/**
 * Created by jimmy on 2019/9/29.
 */
import React, {useState, useEffect} from 'react';
import './page.less';

import * as Utils from '../common/utils';
import * as Request from '../common/request';

const CompPage = props => {
	document.title = props.title || '首页';

	const [ fightStatusFlag, setFightStatusFlag ] = useState(false);
	const [ fightObj, setFightObj ] = useState({});
	const initial = () => {
		Utils.selfEvent.addEvent('fightStatus', (data) => {
			if(data && data.fight_status == 1) {
				setFightStatusFlag(true);
			}
			setFightObj(data);
		});
	};
	useEffect(() => {
		initial();
	}, [123]);

	const acceptFight = async () => {
		const result = await Request.get({
			url: '/against/info',
			data: {
				id: fightObj.id
			}
		});
		if(!result) {
			setFightStatusFlag(false);
			return;
		}

		const _data = result.data || {};
		const userInfo = Utils.user.getInfo();
		if(_data && _data.status == 0) {
			Utils._alert('对手已经离开对战，请重新发起');
		}else if(_data && userInfo && _data.initiator != userInfo.id && _data.challenger != userInfo.id){
			Utils._alert('对手已经离开对战，请重新发起');
		}else{
			setTimeout(() => {
				window.location.href = `/against/mapping?fid=${fightObj.id}&fight_status=2`;
			}, 555);
		}
		setFightStatusFlag(false);
	};

	const rejectFight = async () => {
		setFightStatusFlag(false);
		await Request.post({
			url: '/against/choose',
			data: {
				id: fightObj.id,
				fight_status: 3
			}
		});
	};

	return (
		<section className="page-container" style={{minHeight: window.innerHeight, ...props.style}}>
			{props.children}

			{
				fightStatusFlag && <div className="fight-status-wrap">
					<div className="fight-status-confirm">
						<div className="title">确认</div>
						<div className="content">{fightObj && fightObj.initiator_name} 正在向你发起挑战，是否接受挑战</div>
						<div className="btn">
							<span onClick={acceptFight}><em className="accept" />接受</span>
							<span onClick={rejectFight}><em className="reject" />拒绝</span>
						</div>
					</div>
				</div>
			}
		</section>
	);
};

export default CompPage;