/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import moment from 'moment';

import Page from '../components/page';

import * as CONST from '../common/const';

import './topics.less';

const PageWrapper = props => {
	return (
		<Page title="专题列表">
			<section className="topic-list-wrapper">
				{
					(() => {
						const map = {};
						CONST.projectConf.topics.map(item => {
							if (!map[item.catId]) map[item.catId] = [];
							map[item.catId].push(item);
						});

						const arr = [];
						for (let i in map) arr.push(map[i]);

						const arr2 = [];
						arr.map(item => {
							item.map(itm => {
								arr2.push(itm);
							});
						});

						return arr2;
					})().map((_item, _idx) => {
						return (
							<div className="list-item" key={_idx}>
								<div className="img" style={{backgroundImage: `url(${_item.cover})`}}>
									<div className="list-item-cover">
										<div className="lock" />
										<div className="open-time">已结束</div>
									</div>
								</div>
								<span>{_item.shortTitle}</span>
							</div>
						);

						const lock = moment().format('YYYY-MM-DD HH:mm:ss') < _item.openTime;
						if (lock) {
							return <div className="list-item" key={_idx}>
								<div className="img" style={{backgroundImage: `url(${_item.cover})`}}>
									<div className="list-item-cover">
										<div className="lock" />
										<div className="open-time">{moment(_item.openTime).format('M月DD日')}开放</div>
									</div>
								</div>
								<span>{_item.shortTitle}</span>
							</div>
						}
						return <Link className="list-item" key={_idx} to={`/topic/${_item.id}`}>
							<div className="img" style={{backgroundImage: `url(${_item.cover})`}}/>
							<span>{_item.shortTitle}</span>
						</Link>
					})
				}
			</section>
		</Page>
	);
};

export default PageWrapper;
