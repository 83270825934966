/**
 * Created by jimmy on 2019/11/8.
 */

const IMG_TOPIC_1 = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/topic1.png';
const IMG_TOPIC_2 = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/topic2.png';
const IMG_TOPIC_3 = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/topic3.png';
const IMG_TOPIC_4 = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/topic4.png';
const IMG_TOPIC_5 = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/topic5.png';

let project = 'competition';
// project = 'battle';
export const host = `${project}.yeekin.cn`;
export const apiPrefix = `//api.${project}.yeekin.cn/api`;
export const wsApi = `//${project}.yeekin.cn`;

export const appId = "wx0cee25a37625abc7";
// export const appId = "wx6ef0bb6a5b9e3955";

/**
 * 正则
 * @type {{mobile: RegExp}}
 */
export const regRules = {
	mobile: /^1\d{10}$/,
	idCard: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
};

/**
 * 项目配置
 * @type {{streets: [*], department: [*]}}
 */
const _projectConf = {
	// 街镇
	streets: [
		'赵巷镇党委',
		'徐泾镇党委',
		'华新镇党委',
		'重固镇党委',
		'白鹤镇党委',
		'朱家角镇党委',
		'练塘镇党委',
		'金泽镇党委',
		'夏阳街道党工委',
		'盈浦街道党工委',
		'香花桥街道党工委',
		'区级机关党工委',
		'农业农村委党组',
		'建管委党组',
		'经委党组',
		'科委党组',
		'发改委党组',
		'公安青浦分局党委',
		'司法局党委',
		'国安局党委',
		'水务局党组',
		'市场监管局党组',
		'房管局党组',
		'民政局党组',
		'教育工作党委',
		'卫健工作党委',
		'文旅局党组',
		'绿容局党组',
		'体育局党组',
		'人社局党组',
		'生态环境局党组',
		'规划资源局党组',
		'中山医院青浦分院党委',
		'国资委党委',
		'检察院党组',
		'法院党组',
		'财政局党组',
		'审计局党组',
		'残联党组',
		'总工会党组',
		'工商联党组',
		'机管局党组',
		'应急局党委',
		'团区委党组',
		'妇联党组',
		'民防办党组',
		'税务局党委',
		'统计局党组',
		'侨联党组',
		'城运中心党组',
		'城管执法局党组',
		'退役军人局党组',
		'医保局党组',
		'商务委党组',
		'政务服务办党组',
		'工业园区党委',
		'淀山湖新城公司党委',
		'西虹桥公司党委',
		'青发集团党委',
		'现代农业园区党委',
		'烟糖公司党总支',
		'供销社党委',
		'红十字会党组',
		'人大机关党组',
		'区政府机关党组',
		'政协机关党组',
		'统计调查大队党组',
		'区域办党组'
	],
	// 部门
	department: ['城管', '环境', '民生'],
	// 专题
	topics: [
		{id: 1, title: '党史专题第一期', shortTitle: '党史第一期', cover: IMG_TOPIC_1, catId: 1, openTime: '2020-05-28', lock: false},
		{id: 2, title: '党史专题第二期', shortTitle: '党史第二期', cover: IMG_TOPIC_1, catId: 1, openTime: '2020-05-29', lock: true},
		{id: 3, title: '改革开放史专题第一期', shortTitle: '改革开放史第一期', cover: IMG_TOPIC_2, catId: 2, openTime: '2020-05-30', lock: true},
		{id: 4, title: '改革开放史专题第二期', shortTitle: '改革开放史第二期', cover: IMG_TOPIC_2, catId: 2, openTime: '2020-05-31', lock: true},
		{id: 5, title: '新中国史专题第一期', shortTitle: '新中国史第一期', cover: IMG_TOPIC_3, catId: 3, openTime: '2020-06-01', lock: true},
		{id: 6, title: '新中国史专题第二期', shortTitle: '新中国史第二期', cover: IMG_TOPIC_3, catId: 3, openTime: '2020-06-02', lock: true},
		{id: 7, title: '社会主义发展史专题第一期', shortTitle: '社会主义发展史第一期', cover: IMG_TOPIC_4, catId: 4, openTime: '2020-06-03', lock: true},
		{id: 8, title: '社会主义发展史专题第二期', shortTitle: '社会主义发展史第二期', cover: IMG_TOPIC_4, catId: 4, openTime: '2020-06-04', lock: true},
		{id: 9, title: '青浦革命和发展史专题第一期', shortTitle: '青浦革命和发展史第一期', cover: IMG_TOPIC_5, catId: 5, openTime: '2020-06-05', lock: true},
		{id: 10, title: '青浦革命和发展史专题第二期', shortTitle: '青浦革命和发展史第二期', cover: IMG_TOPIC_5, catId: 5, openTime: '2020-06-06', lock: true},
		{id: 11, title: '党史专题第三期', shortTitle: '党史第三期', cover: IMG_TOPIC_1, catId: 1, openTime: '2020-06-09', lock: true},
		{id: 12, title: '改革开放史专题第三期', shortTitle: '改革开放史第三期', cover: IMG_TOPIC_2, catId: 2, openTime: '2020-06-12', lock: true},
		{id: 13, title: '新中国史专题第三期', shortTitle: '新中国史第三期', cover: IMG_TOPIC_3, catId: 3, openTime: '2020-06-15', lock: true},
		{id: 14, title: '社会主义发展史专题第三期', shortTitle: '社会主义发展史第三期', cover: IMG_TOPIC_4, catId: 4, openTime: '2020-06-18', lock: true},
		{id: 15, title: '青浦革命和发展史专题第三期', shortTitle: '青浦革命和发展史第三期', cover: IMG_TOPIC_5, catId: 5, openTime: '2020-06-21', lock: true},
	],
	getTopicById: id => {
		let topic = {};
		_projectConf.topics.map(item => {
			if (item.id == id) topic = item;
		});
		return topic;
	}
};
export const projectConf = _projectConf;

export const AVATAR_DEFAULT = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/user.png';
