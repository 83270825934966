/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';

import Page from '../components/page';

import * as CONST from '../common/const';
import * as Request from '../common/request';

import './cert.less';

const PageWrapper = props => {
	const [list, setList] = useState([]);
	const getList = async() => {
		let list = window.sessionStorage.getItem('certs');
		list = list && typeof list == 'string' ? JSON.parse(list) : null;
		if(!list) {
			const result = await Request.get({
				url: '/topic/category-lists'
			});
			if(!result) {
				list = [];
			}else{
				list = result.data;
				if(list && list.length) {
					window.sessionStorage.setItem('certs', JSON.stringify(list));
				}
			}
		}
		if(!list) list = [];
		const titleList = list.map(item => item.category);
		const _list = [];

		(() => {
			const map = {};
			CONST.projectConf.topics.map(item => {
				if (!map[item.catId]) map[item.catId] = [];
				map[item.catId].push(item);
			});

			const arr = [];
			for (let i in map) arr.push(map[i]);

			const arr2 = [];
			arr.map(item => {
				item.map(itm => {
					arr2.push(itm);
				});
			});

			return arr2;
		})().map(item => {
			if (titleList.includes(item.title)) {
				item.active = true;
			}
			_list.push(item);
		});
		setList(_list);
	};
	useEffect(() => {
		getList();
	}, [123]);
	return (
		<Page title="学习证书">
			<section className="cert-wrapper">
				<div className="cert-list">
					{
						list && list.length > 0 && list.map((item, idx) => {
							return (
								<div className={ item.active ? 'cert-item active' : 'cert-item' } key={idx}>
									<div className="cover"/>
									<span>{item.shortTitle}</span>
								</div>
							)
						})
					}
				</div>
			</section>
		</Page>
	);
};

export default PageWrapper;
