import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router, Route} from "react-router-dom";

import './index.less';
import * as serviceWorker from './serviceWorker';
import * as Utils from './common/utils';
import * as CONST from './common/const';
import * as WX from './common/wx';

import PageHome from './pages/home';
import PageTopics from './pages/topics';
import PageAnswer from './pages/answer';
import PageAnswerResult from './pages/answer-result';
import PageCert from './pages/cert';
import PageAgainstMap from './pages/against-map';
import PageAgainstAnswer from './pages/against-answer';
import PageAgainstResult from './pages/against-result';
import PageOrderList from './pages/order-list';

import PageDakaHome from './pages/daka-home';
import PageDaka from './pages/daka';
import PageDakaHistory from './pages/daka-history';

// 盈普党建相关页面
import PageDakaObjHome from './pages/dakaobj-home';

const App = () => (
	<Router>
		<div>
			<Route exact path="/" component={PageDakaHome}/>
			<Route exact path="/home" component={PageDakaHome}/>
			<Route exact path="/home/:obj" component={PageDakaObjHome}/>
			<Route exact path="/daka" component={PageDaka} />
			<Route exact path="/history" component={PageDakaHistory} />

			<Route exact path="/ohome" component={PageHome}/>
			<Route exact path="/topics" component={PageTopics}/>
			<Route exact path="/topic/:topicId" component={PageAnswer}/>
			<Route exact path="/topic/:topicId/result/:id" component={PageAnswerResult}/>
			<Route exact path="/certs" component={PageCert}/>
			<Route exact path="/against/mapping" component={PageAgainstMap}/>
			<Route exact path="/against/fight/:fid" component={PageAgainstAnswer}/>
			<Route exact path="/against/:fid/result" component={PageAgainstResult}/>
			<Route exact path="/against/order" component={PageOrderList}/>

			{/*<Route exact path="/yingpudangjian" component={PageYingpuDangjianHome}/>*/}
		</div>
	</Router>
);

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// 用户登陆
Utils.user.login();

// 微信分享
(() => {
	WX.sign();
	WX.share();
})();

// 全局 socket
(() => {
	// const socket = window.io(CONST.wsApi);
	// socket.on('start-channel', function (data) {
	// 	let userInfo = Utils.user.getInfo();
	// 	let obj = JSON.parse(data.data);
	//
	// 	if(obj.status == 0) return;
	// 	if (window.location.href.indexOf('/against/fight') == -1 && (userInfo.id == obj.initiator || userInfo.id == obj.challenger)) {
	// 		window.location.href = `/against/fight/${obj.id}`;
	// 	}
	// });
	//
	// socket.on('choose-channel', function (data) {
	// 	let userInfo = Utils.user.getInfo();
	// 	let obj = JSON.parse(data.data);
	//
	// 	if(obj.status == 0) return;
	// 	if(obj.fight_status == 1 && userInfo.id == obj.challenger) {
	// 		Utils.selfEvent.fireEvent('fightStatus', obj);
	// 	}
	// 	if(obj.fight_status == 2 || (obj.fight_status == 3 && userInfo.id == obj.initiator)) {
	// 		Utils.selfEvent.fireEvent('fightStatusInitial', obj);
	// 	}
	// });

	// socket.on('disconnected', function(data) {
	// 	const userInfo = Utils.user.getInfo();
	// 	socket.emit('user-logout', userInfo.id);
	// });
})();
