/**
 * Created by jimmy on 2019/11/7.
 */
import * as Request from './request';
import * as WX from './wx';
import * as CONST from './const';

export const getSearchByKey = (key, search = window.location.search) => {
	if (!search) return '';

	const _search = search.slice(1);
	const _searchArr = _search.split('&');
	const _map = {};
	_searchArr.map(item => {
		const _item = item.split('=');
		if (_item[0] && _item[1]) {
			_map[_item[0]] = _item[1];
		}
	});
	return _map[key] || '';
};

export const setTitle = title => {
	if (document) {
		document.title = title;
	}
};

// loading
export const loading = {
	show: () => {
		document.getElementById('loading').style.display = 'block';
	},
	hide: () => {
		document.getElementById('loading').style.display = 'none';
	}
};

// toast
export const toast = {
	show: (txt) => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'block';
		$toast.innerText = txt;
	},
	hide: () => {
		const $toast = document.getElementById('toast');
		$toast.style.display = 'none';
		$toast.innerText = '';
	}
};

export const _alert = (txt) => {
	if (!txt) return;

	toast.show(txt);

	setTimeout(() => {
		toast.hide();
	}, 1500);
};

/**
 * 登录功能
 * 微信内：微信登录
 * APP 内：暂时先 return
 */
const _user = {
	getInfo: () => {
		let userInfo = sessionStorage.getItem('userinfo');
		userInfo = userInfo ? JSON.parse(userInfo) : null;
		return userInfo;
	},
	setInfo: (info = {}) => {
		const _userInfo = _user.getInfo();
		sessionStorage.setItem('userinfo', JSON.stringify({..._userInfo, ...info}));
	},
	login: async(loginForm = {}) => {
		let userInfo = _user.getInfo();
		if (!userInfo || !userInfo.openid) {
			userInfo = await WX.login();
		}
		if (!userInfo || !userInfo.access_token) {
			let data = {
				...loginForm,
				openid: userInfo.openid
			};
			if (userInfo.wechatInfo) {
				data.avatar = userInfo.wechatInfo.avatar;
				data.nickname = userInfo.wechatInfo.nickname;
			}
			const result = await Request.post({
				url: '/user/login',
				data
			});
			userInfo = result && result.data || null;
		}
		if (!userInfo) return _alert('登陆失败，请稍后重试');

		if (userInfo.token_type && userInfo.access_token) {
			const token = `${userInfo.token_type} ${userInfo.access_token}`;
			window.sessionStorage.setItem('token', token);
		}
		_user.setInfo(userInfo && (userInfo.user || userInfo) || null);

		return userInfo;
	}
};
export const user = _user;

export const initRandom = len => parseInt(Math.random() * len, 10);

export const secondFormat = (second, format) => {
	if(!format) return second;
	second = parseInt(second);

	let h = Math.floor(second / 3600) < 10 ? '0' + Math.floor(second / 3600) : Math.floor(second / 3600);
	let m = Math.floor((second / 60 % 60)) < 10 ? '0' + Math.floor((second / 60 % 60)) : Math.floor((second / 60 % 60));
	let s = Math.floor((second % 60)) < 10 ? '0' + Math.floor((second % 60)) : Math.floor((second % 60));

	return format.replace('hh', h).replace('mm', m).replace('ss', s);
};

export const selfEvent = {
	_listeners: {},
	// 添加
	addEvent: function(type, fn) {
		if (typeof this._listeners[type] === "undefined") {
			this._listeners[type] = [];
		}
		if (typeof fn === "function") {
			this._listeners[type].push(fn);
		}
		return this;
	},
	// 触发
	fireEvent: function(type, data = {}) {
		const arrayEvent = this._listeners[type];
		if (arrayEvent instanceof Array) {
			for (let i=0, length=arrayEvent.length; i<length; i+=1) {
				if (typeof arrayEvent[i] === "function") {
					arrayEvent[i]({ type: type, ...data });
				}
			}
		}
		return this;
	},
	// 删除
	removeEvent: function(type, fn) {
		const arrayEvent = this._listeners[type];
		if (typeof type === "string" && arrayEvent instanceof Array) {
			if (typeof fn === "function") {
				// 清除当前type类型事件下对应fn方法
				for (let i=0, length=arrayEvent.length; i<length; i+=1){
					if (arrayEvent[i] === fn){
						this._listeners[type].splice(i, 1);
						break;
					}
				}
			} else {
				// 如果仅仅参数type, 或参数fn邪魔外道，则所有type类型事件清除
				delete this._listeners[type];
			}
		}
		return this;
	}
};