/**
 * Created by jimmy on 2019/11/8.
 */

import * as Utils from './utils';

const _question = {
	// 党史
	topic1: [
		{
			txt: '1921年（   ），党的一大在上海开幕，最后一天的会议转移到浙江嘉兴南湖举行。',
			ansList: ['6月20日', '7月1日', '7月5日', '7月23日'],
			ans: '7月23日',
			multi: false
		},
		{
			txt: '标志第一次国共合作全面破裂、大革命最后失败的事件是（    ）',
			ansList: ['“四一二”反革命政变', '“马日”事变', '“七一五分共”事件', '重庆谈判'],
			ans: '“七一五分共”事件',
			multi: false
		},
		{
			txt: '1921年7月在中国共产党第一次全国代表大会上，（   ）被推选为中央局书记。',
			ansList: ['毛泽东', '李大钊', '陈独秀', '蔡和森'],
			ans: '陈独秀',
			multi: false
		},
		{
			txt: '（   ）打响了武装反抗国民党反动派的第一枪，标志着中国共产党独立地领导革命战争,创建人民军队和武装夺取政权的开始。',
			ansList: ['南昌起义', '秋收起义', '广州起义', '武昌起义'],
			ans: '南昌起义',
			multi: false
		},
		{
			txt: '1927年10月，毛泽东率领湘赣边界秋收起义部队到达江西宁冈，开始了创建（   ）的斗争。',
			ansList: ['赣南、闽西革命根据地', '延安革命根据地', '湘赣革命根据地', '井冈山革命根据地'],
			ans: '井冈山革命根据地',
			multi: false
		},
		{
			txt: '1935年1月召开的（   ），确立了毛泽东在党和红军中的领导地位，在极其危急的情况下挽救了党、挽救了红军、挽救了中国革命，是党的历史上一个生死攸关的转折点。',
			ansList: ['古田会议', '遵义会议', '黎平会议', '瓦窑堡会议'],
			ans: '遵义会议',
			multi: false
		},
		{
			txt: '（   ）发生，标志着日本帝国主义发动全面侵华战争和全国抗战的开始。',
			ansList: ['九一八事变', '七七事变', '八一三事变', '一二八事变'],
			ans: '七七事变',
			multi: false
		},
		{
			txt: '1940年8月，八路军发动（   ），取得毙伤日伪军2.5万多人等辉煌战果，沉重打击了日军，进一步提高了共产党和八路军的威望。',
			ansList: ['百团大战', '平型关战斗', '反“扫荡”斗争', '淮海战役'],
			ans: '百团大战',
			multi: false
		},
		{
			txt: '延安整风运动的基本方针是（   ）。',
			ansList: ['反对宗派主义以整顿党风', '彻底纠正“共产”风', '团结-批评-团结', '“惩前毖后”，“治病救人”'],
			ans: '“惩前毖后”，“治病救人”',
			multi: false
		},
		{
			txt: '1945年4月23日至6月11日，党的七大在延安举行。这次会议将（   ）作为党的指导思想写入党章。',
			ansList: ['马克思列宁主义', '毛泽东思想', '实事求是原则', '群众路线'],
			ans: '毛泽东思想',
			multi: false
		},
		{
			txt: '抗日战争胜利后，为争取全国人民渴望的和平民主，中国共产党和国民党举行了（   ），签署了《政府与中共代表会谈纪要》（即《双十协定》）。',
			ansList: ['重庆谈判', '南京谈判', '延安谈判', '西安谈判'],
			ans: '重庆谈判',
			multi: false
		},
		{
			txt: '全国解放战争三大战役中，历时最长、规模最大、歼敌数量最多的是（   ）。',
			ansList: ['辽沈战役', '淮海战役', '平津战役', '西南战役'],
			ans: '淮海战役',
			multi: false
		},
		{
			txt: '人民解放军发动渡江战役，于1949年4月23日占领（   ），宣告延续22年的国民党反动统治的覆灭。',
			ansList: ['北平', '上海', '南京', '天津'],
			ans: '南京',
			multi: false
		},
		{
			txt: '从1950年冬到1952年底，除一部分少数民族地区及台湾省外，广大新解放区的（   ）基本完成。 这摧毁了中国封建制度的经济基础，使农民获得土地等基本生产资料。',
			ansList: ['土地改革运动', '五反运动', '镇反运动', '三反运动'],
			ans: '土地改革运动',
			multi: false
		},
		{
			txt: '过渡时期总路线的主体是（   ）。',
			ansList: ['实现现代化', '实现国际化', '实现国有化', '实现社会主义工业化'],
			ans: '实现社会主义工业化',
			multi: false
		},
		{
			txt: '在为党的八大作准备的过程中，毛泽东作了（   ）。',
			ansList: ['《十年总结》', '《关于正确处理人民内部矛盾的问题》的讲话', '《论十大关系》的讲话', '《论人民民主专政》的讲话'],
			ans: '《论十大关系》的讲话',
			multi: false
		},
		{
			txt: '1957年春，全党开展整风运动的主题是（   ）。',
			ansList: ['百花齐放，百家争鸣', '反对官僚主义', '发扬艰苦奋斗', '正确处理人民内部矛盾'],
			ans: '正确处理人民内部矛盾',
			multi: false
		},
		{
			txt: '党的八届九中全会正式决定对国民经济实行（   ）的八字方针。',
			ansList: ['“调整、改革、整顿、提高”', '“调整、巩固、充实、提高”', '“调整、巩固、改革、提高”', '“调整、修改、改革、提高”'],
			ans: '“调整、巩固、充实、提高”',
			multi: false
		},
		{
			txt: '（   ）提出批判极左思潮的意见，是1967年2月前后许多中央领导同志要求纠正“文化大革命”错误这一正确主张的继续。',
			ansList: ['邓小平', '毛泽东', '周恩来', '刘少奇'],
			ans: '周恩来',
			multi: false
		},
		{
			txt: '1978年5月11日，《光明日报》发表题为（   ）特约评论员文章。由此，一场关于真理标准问题的大讨论在全国展开。',
			ansList: ['《马克思主义的一个最基本原则》', '《解放思想，实事求是，团结一致向前看》', '《实践是检验真理的唯一标准》', '《坚持党的路线，改进工作方法》'],
			ans: '《实践是检验真理的唯一标准》',
			multi: false
		},
		{
			txt: '“建设有中国特色社会主义”是邓小平在（    ）上最早提出来的。',
			ansList: ['党的十一届三中全会', '党的十二大', '党的十二届三中全会', '党的十三大'],
			ans: '党的十二大',
			multi: false
		},
		{
			txt: '1981年6月，党的（   ）通过《关于建国以来党的若干历史问题的决议》。',
			ansList: ['十一届三中全会', '十一届五中全会', '十一届六中全会', '十一届四中全会'],
			ans: '十一届六中全会',
			multi: false
		},
		{
			txt: '1984年10月20日，党的十二届（   ）中全会通过的《关于经济体制改革的决定》，确认我国社会主义经济是公有制基础上有计划的商品的经济。',
			ansList: ['二', '五', '四', '三'],
			ans: '三',
			multi: false
		},
		{
			txt: '1987年召开的党的十三大提出并系统论述了（   ）。',
			ansList: ['社会主义初级阶段理论', '社会主义商品经济理论', '社会主义本质理论', '社会主义中级阶段理论'],
			ans: '社会主义初级阶段理论',
			multi: false
		},
		{
			txt: '党的十四大确定我国经济体制改革的目标是建立（   ）体制。',
			ansList: ['以公有制为基础的有计划的商品经济', '计划与市场相结合的社会主义商品经济', '社会主义市场经济', '社会主义计划经济'],
			ans: '社会主义市场经济',
			multi: false
		},
		{
			txt: '党的十五大通过的（   ）明确规定，中国共产党以马克思列宁主义、毛泽东思想、邓小平理论作为自己的行动指南。',
			ansList: ['决议', '报告', '讲话', '党章修正案'],
			ans: '党章修正案',
			multi: false
		},
		{
			txt: '全面建设小康社会的奋斗目标，是（   ）提出来的。',
			ansList: ['党的十五大', '党的十六大', '党的十七大', '党的十八大'],
			ans: '党的十六大',
			multi: false
		},
		{
			txt: '党的十七大在我党历史上第一次郑重地鲜明地完整地提出了高举（   ）伟大旗帜。',
			ansList: ['马克思主义', '毛泽东思想', '中国特色社会主义', '科学发展观'],
			ans: '中国特色社会主义',
			multi: false
		},
		{
			txt: '党的十九大强调，要加强党的政治建设、思想建设、组织建设、作风建设、纪律建设，增强（   ）、自我完善、自我革新、自我提高能力。',
			ansList: ['自我监督', '自我管理', '自我学习', '自我净化'],
			ans: '自我净化',
			multi: false
		},
		{
			txt: '十九届四中全会审议通过《中共中央关于坚持和完善中国特色社会主义制度、推进（   ）现代化若干重大问题的决定》。',
			ansList: ['国家治理体系', '国家治理效能', '治理能力', '国家治理体系和治理能力'],
			ans: '国家治理体系和治理能力',
			multi: false
		},
		//多选的 ===================================================================================================
		{
			txt: '遵义会议以后，中共中央决定组成三人军事指挥小组，全权负责军事指挥,这三人是(     )。',
			ansList: ['毛泽东', '周恩来', '王稼祥', '朱德', '陈毅'],
			ans: ['毛泽东', '周恩来', '王稼祥'],
			multi: true
		},
		{
			txt: '毛泽东在《〈共产党人〉发刊词》一文中总结的中国革命的三大法宝是(     )。',
			ansList: ['实事求是', '统一战线', '武装斗争', '党的建设', '独立自主'],
			ans: ['统一战线', '武装斗争', '党的建设'],
			multi: true
		},
		{
			txt: '抗日战争时期，按“三三制”原则组成的边区政府，其成员包括(     )。',
			ansList: ['代表工人阶级和贫农的共产党员', '代表小资产阶级的非党左派进步分子', '代表大资产阶级利益的国民党党员', '代表开明绅士和中等资产阶级的中间派', '代表友好国家的进步人士'],
			ans: ['代表工人阶级和贫农的共产党员', '代表小资产阶级的非党左派进步分子', '代表开明绅士和中等资产阶级的中间派'],
			multi: true
		},
		{
			txt: '1942年，中国共产党在全党范围内进行了一次整风运动，其主要任务是(    )。',
			ansList: ['反对主观主义以整顿学风', '反对宗派主义以整顿党风', '反对党八股以整顿文风', '反对分散主义以整顿政风', '反对自由主义以整顿会风'],
			ans: ['反对主观主义以整顿学风', '反对宗派主义以整顿党风', '反对党八股以整顿文风'],
			multi: true
		},
		{
			txt: '中国新民主主义革命的三大经济纲领是(      )。',
			ansList: ['没收封建阶级的土地归农民所有', '没收中农的土地归贫农所有', '没收四大家族为首的垄断资本归新民主主义国家所有', '没收民族资产阶级的资本归新民主主义国家所有', '保护民族工商实业'],
			ans: ['没收封建阶级的土地归农民所有', '没收四大家族为首的垄断资本归新民主主义国家所有', '保护民族工商实业'],
			multi: true
		},
		{
			txt: '解放战争时期，人民解放军进行战略决战的三大战役是(      )。',
			ansList: ['上党战役', '辽沈战役', '淮海战役', '平津战役', '渡江战役'],
			ans: ['辽沈战役', '淮海战役', '平津战役'],
			multi: true
		},
		{
			txt: '党在过渡时期总路线的基本内容包括(     )。',
			ansList: ['逐步实现国家的社会主义工业化', '逐步实现国家对农业的社会主义改造', '逐步实现国家对手工业的社会主义改造', '逐步实现国家对资本主义工商业的社会主义改造', '逐步实现国家对知识分子的社会主义改造'],
			ans: ['逐步实现国家的社会主义工业化', '逐步实现国家对农业的社会主义改造', '逐步实现国家对手工业的社会主义改造', '逐步实现国家对资本主义工商业的社会主义改造'],
			multi: true
		},
		{
			txt: '邓小平提出的我们必须在思想政治上坚持的四项基本原则是(     )。',
			ansList: ['坚持改革开放', '坚持社会主义道路', '坚持共产党的领导', '坚持马列主义、毛泽东思想', '坚持人民民主专政'],
			ans: ['坚持社会主义道路', '坚持共产党的领导', '坚持马列主义、毛泽东思想', '坚持人民民主专政'],
			multi: true
		},
		{
			txt: '中国共产党与各民主党派实行(      )的方针。',
			ansList: ['长期共存', '互相监督', '肝胆相照', '荣辱与共', '互相支持'],
			ans: ['长期共存', '互相监督', '肝胆相照', '荣辱与共'],
			multi: true
		},
		{
			txt: '党在社会主义初级阶段的基本路线是：领导和团结全国各族人民，以经济建设为中心，坚持四项基本原则，坚持改革开放，自力更生，艰苦创业，为把我国建设成为(      )的社会主义现代化国家而奋斗。',
			ansList: ['富强', '民主', '文明', '和谐', '繁荣'],
			ans: ['富强', '民主', '文明'],
			multi: true
		}
	],
	// 改革开放史
	topic2: [
		{
			txt: '邓小平同志首次提出“解放思想、实事求是”是在（     ）',
			ansList: ['1977年党的十届三中全会上', '1978年中央工作会议上', '1978年全军政治工作会议上', '1978年党的十一届三中全会上'],
			ans: '1978年中央工作会议上',
			multi: false
		},
		{
			txt: '以(    )为标志，党和国家进入了以改革开放和社会主义现代化建设为主要任务的历史新时期。',
			ansList: ['粉碎“四人帮”', '党的十一届三中全会', '实行农村土地承包制', '党的十四大'],
			ans: '党的十一届三中全会',
			multi: false
		},
		{
			txt: '中国改革的第一个突破口是（    ）',
			ansList: ['农村改革', '城市改革', '国有企业改革', '发展个体、私营经济'],
			ans: '农村改革',
			multi: false
		},
		{
			txt: '二十世纪七十年代，首先进行“分田包产到户，自负盈亏”的是(   )小岗村农民。',
			ansList: ['安徽肥西', '安徽凤阳', '河北沧州', '贵州关岭'],
			ans: '安徽凤阳',
			multi: false
		},
		{
			txt: '1980年8月，邓小平（     ）重要讲话提出了党和国家领导制度改革的基本指导思想，为我国政治体制改革指明了方向。',
			ansList: ['《高级干部要带头发扬党的优良传统》', '《加强党的领导，整顿党的作风》  ', '《党和国家领导制度的改革》', '《关于政治体制改革问题》'],
			ans: '《党和国家领导制度的改革》',
			multi: false
		},
		{
			txt: '1980年，我国在福建设立的经济特区是（  ）。',
			ansList: ['深圳', '珠海', '汕头', '厦门'],
			ans: '厦门',
			multi: false
		},
		{
			txt: '经济特区作为我国对外开放窗口，推动中国的发展。经济特区“特”在实行（    ）',
			ansList: ['“一国两制”', '特殊和优惠的经济政策', '民族区域自治制度', '少数民族文化政策'],
			ans: '特殊和优惠的经济政策',
			multi: false
		},
		{
			txt: '1985年，以（   ）为重心的经济体制改革在中国全面开展。',
			ansList: ['企业', '城市', '农村', '城乡'],
			ans: '城市',
			multi: false
		},
		{
			txt: '(   )年9月5日,邓小平在会见捷克斯洛伐克总统胡萨克时,提出了“科学技术是第一生产力”的著名论断。',
			ansList: ['1987', '1988', '1989', '1990'],
			ans: '1988',
			multi: false
		},
		{
			txt: '.以（    ）为标志，我们党开创了以经济建设为中心的历史发展新时期，找到了建设和发展中国特色社会主义的新道路。   ',
			ansList: ['“文化大革命”的结束', '改革开放', '中国共产党第十二次全国代表大会', '党的十九大'],
			ans: '改革开放',
			multi: false
		},
		{
			txt: '有一首歌唱到“一九七九年那是一个春天”，“一九九二年又是一个春天”，歌中的两个“春天”分别喻指（    ）。 ',
			ansList: ['“三步走”部署和“一个中心两个基本点”', '建立经济特区和邓小平南方讲话', '国有企业改革和建立社会主义市场经济体制', '家庭联产承包责任制和国有企业改革'],
			ans: '建立经济特区和邓小平南方讲话',
			multi: false
		},
		{
			txt: '在改革开放中，经过三次思想解放，我们先后冲破了“个人崇拜”、“计划经济崇拜”和“所有制崇拜”，其中冲破“计划经济崇拜”的关键事件是（    ）。 ',
			ansList: ['党的十二大', '党的十三大', '党的十四大', '党的十五大'],
			ans: '党的十四大',
			multi: false
		},
		{
			txt: '我国实行对外开放的根本目的是（    ）。 ',
			ansList: ['使我国经济尽快与国际接轨', '增强我国经济实力，加快现代化建设步伐', '积极参与国际分工，分享新技术的成果', '进一步提升我国的对外开放水平'],
			ans: '增强我国经济实力，加快现代化建设步伐',
			multi: false
		},
		{
			txt: '邓小平的对外开放思想中最具有远见卓识的伟大创举，最富有实践效应的重要内容是（     ）。   ',
			ansList: ['提出现在的世界是开放的世界', '建立经济特区', '建立全方位、多层次、宽领域的开放格局', '全面发展生产力'],
			ans: '建立经济特区',
			multi: false
		},
		{
			txt: '20世纪80年代上海成立的三个国家级开发区是（    ）。',
			ansList: ['虹桥经济技术开发区、闵行经济技术开发区、上海高新技术产业开发区', '虹桥经济技术开发区、上海高新技术产业开发区、漕河泾新兴技术开发区', '虹桥经济技术开发区、闵行经济技术开发区、漕河泾新兴技术开发区', '上海高新技术产业开发区、闵行经济技术开发区、漕河泾新兴技术开发区'],
			ans: '虹桥经济技术开发区、闵行经济技术开发区、漕河泾新兴技术开发区',
			multi: false
		},
		{
			txt: '改革开放以来，我国已经进行了（     ）次国务院政府机构改革，力图降低行政成本，提高行政效率。',
			ansList: ['5', '6', '7', '8'],
			ans: '8',
			multi: false
		},
		{
			txt: '1984年，有国际媒体评论：“中国政府开放整个海岸，意味着实际开放半个中国，它是三十五年来中国政府所采取的最大胆行动。”这个“大胆行动”指的是（     ）。',
			ansList: ['开放十四个沿海城市', '设珠江三角洲开放区', '设立深圳经济特区', '开发开放上海浦东'],
			ans: '开放十四个沿海城市',
			multi: false
		},
		{
			txt: '邓小平提出了我国现代化发展“三步走”战略：第一步，从1981年到1990年国民生产总值翻一番，实现温饱；第二步，从1991年到20世纪末再翻一番，达到小康；第三步，（    ）。 ',
			ansList: ['到21世纪中叶再翻两番，达到发达国家水平', '到21世纪中叶再翻两番，达到全面小康社会水平', '到21世纪中叶再翻两番，达到中等发达国家水平', '到21世纪中叶再翻一番，达到中等发达国家水平'],
			ans: '到21世纪中叶再翻两番，达到中等发达国家水平',
			multi: false
		},
		{
			txt: '改革开放使我国成功实现了（     ）两大历史转折。',
			ansList: ['从封闭半封闭到全方位开放；从工业化到信息化。', '从高度集中的计划经济体制到充满活力的社会主义市场经济体制；从封闭半封闭到全方位开放。', '从高度集中的计划经济体制到充满活力的社会主义市场经济体制；从城镇化到国际化。', '从封闭半封闭到全方位开放；从城镇化到国际化。'],
			ans: '从高度集中的计划经济体制到充满活力的社会主义市场经济体制；从封闭半封闭到全方位开放。',
			multi: false
		},
		{
			txt: '我国改革的性质是（     ）。',
			ansList: ['改革实质上是要改革社会主义制度', '改革是一个阶级改变一个阶级的政治地位的阶级斗争', '改革是社会主义制度的自我完善和发展', '改革就是改良主义'],
			ans: '改革是社会主义制度的自我完善和发展',
			multi: false
		},
		{
			txt: '处理好改革、发展、稳定的关系，关键是要始终注意(  )。',
			ansList: ['维护人民群众的利益', '深化改革，扩大开放', '以经济建设为中心', '坚持四项基本原则'],
			ans: '维护人民群众的利益',
			multi: false
		},
		{
			txt: '“上海是我们的王牌，把上海搞起来是一条捷径。”为了加快现代化建设的步伐，20世纪90年代中共中央决定（    ）',
			ansList: ['改造浦西市政', '开发开放浦东', '建立经济特区', '整顿金融秩序'],
			ans: '开发开放浦东',
			multi: false
		},
		{
			txt: '2003年10月，党的(   )通过《关于完善社会主义市场经济体制若干问题的决定》。',
			ansList: ['十二届三中全会', '十四届三中全会', '十五届三中全会', '十六届三中全会'],
			ans: '十六届三中全会',
			multi: false
		},
		{
			txt: '改革开放极大地解放和发展了社会生产力，推动了社会主义市场经济体制的（     ）建立。',
			ansList: ['完全', '初步', '全面', '基本'],
			ans: '初步',
			multi: false
		},
		{
			txt: '北京（   ）赢得2008年奥运会举办权。   ',
			ansList: ['2001年7月13日', '2001年8月8日', '2008年7月13日', '2008年8月8日'],
			ans: '2001年7月13日',
			multi: false
		},
		{
			txt: '被誉为强国之路的是(   )。 ',
			ansList: ['四个坚持', '改革开放', '党的建设伟大工程', '科学发展'],
			ans: '改革开放',
			multi: false
		},
		{
			txt: '加快转变经济发展方式，关键是（    ），促进科技成果向现实生产力转化。',
			ansList: ['加快企业技术升级', '全面提高自主创新能力', '走集约型发展道路', '走特色新型工业化之路'],
			ans: '全面提高自主创新能力',
			multi: false
		},
		{
			txt: '党的十八届三中全会决定设立国家（    ），完善国家安全体制和国家安全战略，确保国家安全。',
			ansList: ['安全保障委', '安全委员会', '安全局', '情报局'],
			ans: '安全委员会',
			multi: false
		},
		{
			txt: '2019年8月，中共中央、国务院印发了关于支持（    ）建设中国特色社会主义先行示范区的意见。',
			ansList: ['北京', '上海', '广州', '深圳'],
			ans: '深圳',
			multi: false
		},
		{
			txt: '中国开放的大门不会关闭。要以“一带一路”建设为重点,坚持引进来和走出去并重,遵循(    )原则,加强创新能力开放合作。',
			ansList: ['共商共建共享', '互惠互利共赢', '共商共建共管', '磋商互通有无'],
			ans: '共商共建共享',
			multi: false
		},
		//多选的 ===================================================================================================
		{
			txt: '1979年，邓小平提出在改革开放过程中必须坚持的四项基本原则是（     ）。',
			ansList: ['坚持社会主义道路', '坚持人民民主专政', '坚持中国共产党的领导', '坚持马列主义、毛泽东思想'],
			ans: ['坚持社会主义道路', '坚持人民民主专政', '坚持中国共产党的领导', '坚持马列主义、毛泽东思想'],
			multi: true
		},
		{
			txt: '1979年7月15日，中共中央、国务院在（     ）试办出口特区。',
			ansList: ['深圳', '珠海', '汕头', '厦门'],
			ans: ['深圳', '珠海', '汕头', '厦门'],
			multi: true
		},
		{
			txt: '坚持改革正确方向的核心是(      )。',
			ansList: ['坚持和完善党的领导', '坚持和发展中国特色社会主义', '坚持社会主义市场经济改革方向', '坚持中国制造'],
			ans: ['坚持和完善党的领导', '坚持和发展中国特色社会主义', '坚持社会主义市场经济改革方向'],
			multi: true
		},
		{
			txt: '改革开放是党在新的时代条件下带领人民进行的新的伟大革命，目的是（    ）。  ',
			ansList: ['解放和发展生产力，实现国家现代化，让中国人民富裕起来，振兴伟大的中华民族', '推动我国社会主义制度自我完善和发展，赋予社会主义新的生机活力，建设和发展中国特色社会主义', '引领当代中国发展进步,加强和改进党的建设，保持和发展党的先进性，确保党始终走在时代前列', '展示一个负责任大国的国际形象'],
			ans: ['解放和发展生产力，实现国家现代化，让中国人民富裕起来，振兴伟大的中华民族', '推动我国社会主义制度自我完善和发展，赋予社会主义新的生机活力，建设和发展中国特色社会主义', '引领当代中国发展进步,加强和改进党的建设，保持和发展党的先进性，确保党始终走在时代前列'],
			multi: true
		},
		{
			txt: '坚持统筹兼顾的根本方法，就是要（    ）。 ',
			ansList: ['统筹城乡发展、区域发展、经济社会发展、人与自然和谐发展、国内发展和对外开放', '统筹中央和地方关系 ', '统筹个人利益与集体利益、局部利益和整体利益、当前利益和长远利益', '统筹国内国际两个大局，树立世界眼光，加强战略思维 '],
			ans: ['统筹城乡发展、区域发展、经济社会发展、人与自然和谐发展、国内发展和对外开放', '统筹中央和地方关系 ', '统筹个人利益与集体利益、局部利益和整体利益、当前利益和长远利益', '统筹国内国际两个大局，树立世界眼光，加强战略思维 '],
			multi: true
		},
		{
			txt: '坚持发展依靠人民，就要全面贯彻（    ）的方针，积极营造鼓励人民干事业、支持人民干成事业、帮助人民干好事业的社会氛围，充分激发各方面的创造能力。 ',
			ansList: ['尊重劳动', '尊重知识', '尊重创造', '尊重人才'],
			ans: ['尊重劳动', '尊重知识', '尊重创造', '尊重人才'],
			multi: true
		},
		{
			txt: '建设和谐文明，培育文明风尚，要大力弘扬（    ）的思想。 ',
			ansList: ['解放思想', '爱国主义', '集体主义', '社会主义'],
			ans: ['爱国主义', '集体主义', '社会主义'],
			multi: true
		},
		{
			txt: '进入新世纪新阶段，社会主义文化更加繁荣，同时人们思想活动的（    ）明显增强，对发展社会主义先进文化提出了更高要求。',
			ansList: ['独立性', '选择性', '多元性', '多变性'],
			ans: ['独立性', '选择性', '多元性'],
			multi: true
		},
		{
			txt: '我国对外开放的格局的特点是（    ）。',
			ansList: ['全方位', '多层次', '宽领域', '多渠道'],
			ans: ['全方位', '多层次', '宽领域'],
			multi: true
		},
		{
			txt: '十八届三中全会决定成立全面深化改革领导小组，领导小组的主要职责是(    )。',
			ansList: ['统一部署全国性重大改革', '统筹推进各领域改革', '协调各方力量形成推进改革合力', '加强督促检查，推动全面落实改革目标任务'],
			ans: ['统一部署全国性重大改革', '统筹推进各领域改革', '协调各方力量形成推进改革合力', '加强督促检查，推动全面落实改革目标任务'],
			multi: true
		},
	],
	// 新中国史
	topic3: [
		{
			txt: '1949年9月21日，毛泽东在中国人民政治协商会议第一届全体会议上发表的开幕词是（   ）。',
			ansList: ['《中国革命和中国共产党》', '《论人民民主专政》', '《中国人民站起来了》', '《论联合政府》'],
			ans: '《中国人民站起来了》',
			multi: false
		},

		{
			txt: '《共同纲领》确定中华人民共和国的性质是(    )。',
			ansList: ['工人阶级专政', '人民民主专政', '工农民主专政', '资产阶级专政'],
			ans: '人民民主专政',
			multi: false
		},

		{
			txt: '新中国成立初期，人民政府为稳定市场，安定人心，严厉打击投机倒把活动，经过(     )，稳定了物价，结束了我国连续十多年物价暴涨的局面。',
			ansList: ['“银元之战”和“米棉之战”', '“银元之战”和“煤油之战”', '“煤油之战”和“米棉之战”', '“米棉之战”和“债券之战”'],
			ans: '“银元之战”和“米棉之战”',
			multi: false
		},

		{
			txt: '新中国成立后制定的第一部基本法律是（   ）。',
			ansList: ['《中华人民共和国民族区域自治实施纲要》', '《中华人民共和国婚姻法》', '《中国人民共和国土地改革法》', '《中国人民共和国宪法》'],
			ans: '《中华人民共和国婚姻法》',
			multi: false
		},

		{
			txt: '1950年6月朝鲜战争爆发，10月19日（     ）为总司令员的中国人民志愿军奉命开赴朝鲜战场。',
			ansList: ['罗荣桓', '彭德怀', '聂荣臻', '贺龙'],
			ans: '彭德怀',
			multi: false
		},

		{
			txt: '1954年9月，第一届全国人民代表大会第一次会议在北京举行。会议的召开，标志着（   ）作为新中国的根本政治制度正式确立。',
			ansList: ['共产党领导的多党合作、政治协商制度', '民族区域自治制度', '公有制为主体的经济制度', '人民代表大会制度'],
			ans: '人民代表大会制度',
			multi: false
		},

		{
			txt: '.“一五”计划，是指我国（    ）发展国民经济的计划。',
			ansList: ['从1950年到1954年', '从1953年到1957年', '从1951年到1955年', '从1956年到1960年'],
			ans: '从1953年到1957年',
			multi: false
		},

		{
			txt: '第（    ）届全国人民代表大会提出了“四个现代化”的宏伟目标。',
			ansList: ['二', '三', '四', '五'],
			ans: '三',
			multi: false
		},
		{
			txt: '1957年2月，毛泽东发表了《关于正确处理人民内部矛盾的问题》的讲话，指出在社会主义基本制度确立后，正确处理人民内部矛盾已经成为国家政治生活的主题，解决人民内部矛盾，只能用民主的、说服的、教育的、(    )的方法去解决。',
			ansList: ['阶级斗争', '急风暴雨式的群众斗争', '解决敌我矛盾', '“团结—批评—团结”'],
			ans: '“团结—批评—团结”',
			multi: false
		},
		{
			txt: '1956年4月，毛泽东在中共中央政治局扩大会议上提出在科学文化工作中实行（    ）的方针。',
			ansList: ['百花齐放，百家争鸣', '为人民服务，为社会主义服务', '贴近生活、贴近实际、贴近群众', '发展民族的科学的大众的文化'],
			ans: '百花齐放，百家争鸣',
			multi: false
		},

		{
			txt: '20世纪60年代前期，为克服困难，许多地方的农村自发搞起了各种形式的(    )。',
			ansList: ['包产到户', '互助组', '责任田', '生产大队'],
			ans: '包产到户',
			multi: false
		},

		{
			txt: '我国第一颗原子弹于(    )爆炸成功。',
			ansList: ['1963年', '1964年', '1965年', '1966年'],
			ans: '1964年',
			multi: false
		},

		{
			txt: '1971年毛泽东、周恩来邀请美国一球队的队员访华，展开了著名的（    ）。',
			ansList: ['篮球外交', '足球外交', '乒乓外交', '网球外交'],
			ans: '乒乓外交',
			multi: false
		},

		{
			txt: '1971年10月25日，第（   ）届联合国大会以压倒多数的票数，通过了恢复中华人民共和国在联合国的一切合法权利和立即把台湾当局的代表从联合国及其所属一切机构中驱逐出去的2758号决议。',
			ansList: ['24', '25', '26', '28'],
			ans: '26',
			multi: false
		},

		{
			txt: '第一个与新中国建交的西方大国是（    ），对中国加强同西欧的关系起到了重要作用。',
			ansList: ['英国', '德国', '美国', '法国'],
			ans: '法国',
			multi: false
		},

		{
			txt: '（    ），美利坚合众国总统理查德•尼克松到达北京。这是美国总统第一次访问中华人民共和国。',
			ansList: ['1972年2月21日', '1971年1月21日', '1971年2月21日', '1972年1月21日'],
			ans: '1972年2月21日',
			multi: false
		},

		{
			txt: '（   ），高考制度得到恢复，全国高校重新通过统一考试招收新生。',
			ansList: ['1977年', '1978年', '1979年', '1980年'],
			ans: '1977年',
			multi: false
		},

		{
			txt: '1982年9月，邓小平会见当时英国首相时，公开提出了“一国两制”的构想。这位首相的名字是（    ）',
			ansList: ['梅杰', '撒切尔夫人', '布莱尔', '希思'],
			ans: '撒切尔夫人',
			multi: false
		},

		{
			txt: '1984年5月召开的（    ）正式批准了“一国两制”的构想，标志着这一科学思想正式成为和平统一祖国的基本国策。',
			ansList: ['六届人大二次会议', '六届人大三次会议', '十二届三中全会', '十一届三中全会'],
			ans: '六届人大二次会议',
			multi: false
		},

		{
			txt: '1986年3月3日，王大珩等四位科学家给中共中央写信，提出要跟踪世界先进水平，发展我国高技术的建议。（    ）当即批示“此事宜速作决断。不可拖延”。后来，中共中央、国务院批准了《高技术研究发展计划（“863”计划）纲要》。',
			ansList: ['胡耀邦', '邓小平', '陈云', '李先念'],
			ans: '邓小平',
			multi: false
		},

		{
			txt: '中国加入世贸组织WTO的时间是（    ）',
			ansList: ['2002年', '2001年', '2000年', '1999年'],
			ans: '2001年',
			multi: false
		},

		{
			txt: '1997年（    ），我国恢复行使对香港的主权，标志着“一国两制”构想的巨大成功，标志着中国人民在完成祖国统一大业道路上迈出了重要的一步。',
			ansList: ['6月1日', '7月1日', '8月1日', '12月20日'],
			ans: '7月1日',
			multi: false
		},

		{
			txt: '中国第一颗绕月探测卫星嫦娥一号于（    ）发射成功并进入预定地球轨道。',
			ansList: ['2005', '2007', '2009', '2011'],
			ans: '2007',
			multi: false
		},

		{
			txt: '2002年11月，党的十六大提出全面建设（    ）的奋斗目标，把“三个代表”重要思想同马克思列宁主义、毛泽东思想、邓小平理论一道确立为党必须长期坚持的指导思想。',
			ansList: ['小康社会', '法治社会', '和谐社会', '法制社会'],
			ans: '小康社会',
			multi: false
		},

		{
			txt: '2008年9月，我国神舟七号载人航天飞行取得圆满成功。这标志着我国载人航天工程“三步走战略”的第二步取得突破性进展，我国由此成为世界上（    ）独立掌握空间出舱关键技术的国家。',
			ansList: ['第二个', '第一个', '第四个', '第三个'],
			ans: '第三个',
			multi: false
		},

		{
			txt: '2008年，中国体育代表团在29届北京奥运会上，获得（    ）枚金牌，名列奖牌榜（    ）。',
			ansList: ['51，第一名', '41，第二名', '31，第三名', '21，第四名'],
			ans: '51，第一名',
			multi: false
		},

		{
			txt: '中国梦的基本内涵是实现（   ）。',
			ansList: ['国家富强、民族振兴、人民幸福', '国家强大、民族振兴', '经济发展、社会和谐', '生态美丽、人民幸福'],
			ans: '国家富强、民族振兴、人民幸福',
			multi: false
		},

		{
			txt: '（   ）是当代中国发展进步的根本方向，是实现中国梦的必由之路，也是引领我国工人阶级走向更加光明未来的必由之路。',
			ansList: ['中国特色社会主义', '民族振兴', '富国强兵', '改革开放'],
			ans: '中国特色社会主义',
			multi: false
		},

		{
			txt: '国特色社会主义进入新时代，我国社会主要矛盾已经转化为（   ）之间的矛盾。',
			ansList: ['人民日益增长的物质文化需要同落后的社会生产之间的矛盾', '人民日益增长的美好生活需要同落后的社会生产力之间的矛盾', '人民日益增长的美好生活需要和不平衡不充分的发展之间的矛盾', '人民日益增长的物质文化需要和不平衡不充分的发展之间的矛盾'],
			ans: '人民日益增长的美好生活需要和不平衡不充分的发展之间的矛盾',
			multi: false
		},

		{
			txt: '从二〇三五年到本世纪中叶，在基本实现现代化的基础上，再奋斗十五年，把我国建成（     ）的社会主义现代化强国。',
			ansList: ['富强民主文明和谐', '国际影响力领先', '富强民主文明和谐美丽', '富强民主美丽和谐'],
			ans: '富强民主文明和谐美丽',
			multi: false
		},
		//多选的 ===================================================================================================
		{
			txt: '1949年中国人民政治协商会议的内容有(     )。',
			ansList: ['制定以经济建设为中心的方针', '通过《共同纲领》', '确定北京作为新中国的首都', '选举产生政务院总理、副总理'],
			ans: ['通过《共同纲领》', '确定北京作为新中国的首都'],
			multi: true
		},
		{
			txt: '新中国成立之初，国民经济遇到严重困难，其主要原因是(     )。',
			ansList: ['解放前夕，国民经济趋于全面崩溃', '帝国主义进行经济封锁', '新解放区农村经济凋敝、城镇工业停产，经济还没有恢复', '国家财政赤字增加'],
			ans: ['解放前夕，国民经济趋于全面崩溃', '帝国主义进行经济封锁', '国家财政赤字增加'],
			multi: true
		},
		{
			txt: '我国的下列外交成就，在20世纪70年代取得的有(      )。',
			ansList: ['我国在联合国的合法权利得到恢复', '美国总统尼克松访华，两国关系开始走上正常化', '中日邦交实现正常化', '我国第一次以世界五大国地位参加国际会议'],
			ans: ['我国在联合国的合法权利得到恢复', '美国总统尼克松访华，两国关系开始走上正常化', '中日邦交实现正常化'],
			multi: true
		},
		{
			txt: '1980年8月，邓小平在中央政治局扩大会议上提出了逐步实现各级干部“四化”的要求，其内容是（     ）。  ',
			ansList: ['革命化', '年轻化', '知识化', '技术化', '专业化'],
			ans: ['革命化', '年轻化', '知识化', '专业化'],
			multi: true
		},
		{
			txt: '社会主义市场经济体制的基本特征是(      )。',
			ansList: ['在所有制结构上，以公有制为主体、多种所有制经济共同发展', '在分配制度上，以按劳分配为主体、多种分配方式并存的制度', '在宏观调控上，能更好地发挥计划和市场两种手段的长处', '在政府职能上，实行政企分开，政府不直接干预企业的生产经营活动'],
			ans: ['在所有制结构上，以公有制为主体、多种所有制经济共同发展', '在分配制度上，以按劳分配为主体、多种分配方式并存的制度', '在宏观调控上，能更好地发挥计划和市场两种手段的长处'],
			multi: true
		},

		{
			txt: '.保持香港、澳门长期繁荣稳定，必须全面准确贯彻（     ）的方针。',
			ansList: ['“港人治港”', '“一国两制”', '“澳人治澳”', '高度自治'],
			ans: ['“港人治港”', '“一国两制”', '“澳人治澳”', '高度自治'],
			multi: true
		},
		{
			txt: '在处理国家之间的关系上，中国坚持的原则是(     )。',
			ansList: ['相互尊重主权和领土完整', '互不侵犯', '平等互利、和平共处', '互不干涉内政', '坚持依法治国'],
			ans: ['相互尊重主权和领土完整', '互不侵犯', '平等互利、和平共处', '互不干涉内政'],
			multi: true
		},
		{
			txt: '习近平总书记指出，实现中国梦，必须（     ）。',
			ansList: ['走中国道路', '弘扬中国精神', '涵养中国气质', '凝聚中国力量'],
			ans: ['走中国道路', '弘扬中国精神', '凝聚中国力量'],
			multi: true
		},
		{
			txt: '深化供给侧结构性改革，必须建设(      )劳动者大军。',
			ansList: ['知识型', '技能型', '创新型', '智慧型'],
			ans: ['知识型', '技能型', '创新型'],
			multi: true
		},
		{
			txt: '.十九大报告指出，要完善(      )等制度，健全农村留守儿童和妇女、老年人关爱服务体系。',
			ansList: ['社会救助', '社会福利', '慈善事业', '优抚安置'],
			ans: ['社会救助', '社会福利', '慈善事业', '优抚安置'],
			multi: true
		}
	],
	// 社会主义发展史
	topic4: [
		{
			txt: '1847年，马克思恩格斯创立的（    ）是第一个国际性的无产阶级政党。',
			ansList: ['正义者同盟', '工人同盟', '四季社', '共产主义者同盟'],
			ans: '共产主义者同盟',
			multi: false
		},
		{
			txt: '直接推动第一国际成立的是（     ）。',
			ansList: ['波兰1863年起义', '1866年英国裁缝工人大罢工', '巴黎公社', '1868年瑞士日内瓦建筑工人大罢工'],
			ans: '波兰1863年起义',
			multi: false
		},
		{
			txt: '1864年英、法、德、意四国工人代表在伦敦开会成立，马克思代表德国工人参加该组织的工作，并逐渐用（    ）作为组织指导思想。',
			ansList: ['空想社会主义理论', '特色社会主义理论', '科学社会主义理论', '无政府主义理论'],
			ans: '科学社会主义理论',
			multi: false
		},
		{
			txt: '（    ）是科学共产主义的第一个纲领性文献，标志着马克思主义的诞生。',
			ansList: ['《社会主义从空想到科学的发展》', '《〈共产党人〉发刊词》', '《德意志意识形态》', '《共产党宣言》'],
			ans: '《共产党宣言》',
			multi: false
		},
		{
			txt: '《社会主义从空想到科学的发展》作者是（     ）。',
			ansList: ['马克思', '恩格斯', '马克思和恩格斯', '列宁'],
			ans: '恩格斯',
			multi: false
		},
		{
			txt: '“一个幽灵，共产主义的幽灵，在欧洲游荡。为了对这个幽灵进行神圣的围剿，旧欧洲的一切势力，教皇和沙皇.梅特涅和基佐.法国的激进派和德国的警察，都联合起来了。”这段话出自（     ）。',
			ansList: ['《社会主义从空想到科学的发展》', '《〈共产党人〉发刊词》', '《德意志意识形态》', '《共产党宣言》'],
			ans: '《共产党宣言》',
			multi: false
		},
		{
			txt: '（     ）是世界上无产阶级武装暴力直接夺取城市政权的第一次尝试。',
			ansList: ['波兰1863年起义', '1866年英国裁缝工人大罢工', '巴黎公社', '1868年瑞士日内瓦建筑工人大罢工'],
			ans: '巴黎公社',
			multi: false
		},
		{
			txt: '在社会主义建设道路初步探索的过程中，毛泽东提出了（     ）发展国民经济的总方针。',
			ansList: ['以农业为基础，以工业为主导，以农轻重为序', '以农业为基础，以重工业为主导，以农轻重为序', '以工业为基础，以农业为主导，以工农兵为序', '全面发展，综合平衡'],
			ans: '以农业为基础，以工业为主导，以农轻重为序',
			multi: false
		},
		{
			txt: '新中国成立以后，顺利地进行了社会主义改造，完成了（    ）的过渡，确立了社会主义基本制度，发展了社会主义的经济、政治和文化。',
			ansList: ['从资本主义到社会主义', '从旧民主主义到新民主主义', '从新民主主义到社会主义', '从建立新中国到大力发展社会主义'],
			ans: '从新民主主义到社会主义',
			multi: false
		},
		{
			txt: '我国社会主义三大改造的实质是（    ）。',
			ansList: ['解决土地问题', '解决分配制度问题', '解决所有制问题', '解决公私关系问题'],
			ans: '解决所有制问题',
			multi: false
		},
		{
			txt: '在我国社会主义改造中，用和平赎买的方法进行改造的是（     ）。',
			ansList: ['个体农业', '个体手工业', '资本主义工商业', '公私合营企业'],
			ans: '资本主义工商业',
			multi: false
		},
		{
			txt: '1956年4月和5月，毛泽东在《论十大关系》的报告中初步总结了我国社会主义建设的经验，明确提出了（     ）。',
			ansList: ['党在过渡时期的总路线', '以苏为鉴，独立自主地探索适合中国情况的社会主义建设道路', '建设有中国特色社会主义', '多快好省地建设社会主义'],
			ans: '以苏为鉴，独立自主地探索适合中国情况的社会主义建设道路',
			multi: false
		},
		{
			txt: '我国对农业进行社会主义改造的主要方式是（     ）。',
			ansList: ['赎买', '统购统销', '公私合营', '合作化'],
			ans: '合作化',
			multi: false
		},
		{
			txt: '社会主义改造基本完成后，我国国家政治生活的主题是（     ）。',
			ansList: ['解决敌我矛盾', '健全民主和法制', '进行政治体制改革', '正确处理人民内部矛盾'],
			ans: '正确处理人民内部矛盾',
			multi: false
		},
		{
			txt: '毛泽东提出社会主义社会基本矛盾和两类不同性质矛盾学说的著作是（      ）。',
			ansList: ['《论十大关系》', '《矛盾论》', '《论人民民主专政》', '《关于正确处理人民内部矛盾的问题》'],
			ans: '《关于正确处理人民内部矛盾的问题》',
			multi: false
		},
		{
			txt: '为了走中国工业化道路，党的八大提出（     ）的方针。',
			ansList: ['以经济建设为中心的方针', '以阶级斗争为中心的方针', '既反保守又反冒进，在综合平衡中稳步前进的方针', '统筹兼顾的方针'],
			ans: '既反保守又反冒进，在综合平衡中稳步前进的方针',
			multi: false
		},
		{
			txt: '建国以来我们在社会主义建设中所经历的曲折和失误归根结底在于没有完全搞清楚（      ）。',
			ansList: ['阶级斗争和经济建设的关系', '解放生产力和发展生产力的关系', '什么是社会主义.怎样建设社会主义', '计划与市场的关系'],
			ans: '什么是社会主义.怎样建设社会主义',
			multi: false
		},
		{
			txt: '邓小平指出：“贫穷不是社会主义，社会主义要消灭贫穷”这个判断（    ）。',
			ansList: ['指出了社会主义的根本任务', '概括了社会主义生产的目的', '明确了社会主义的发展方向', '体现了社会主义本质的要求'],
			ans: '体现了社会主义本质的要求',
			multi: false
		},
		{
			txt: '1982年党的（    ），邓小平首次提出“建设有中国特色的社会主义”，成为新时期改革、建设的指导思想。',
			ansList: ['十一届三中全会', '十二大', '十三大', '十四大'],
			ans: '十二大',
			multi: false
		},
		{
			txt: '党的十七大在我党历史上第一次郑重地鲜明地完整地提出了高举（   ）伟大旗帜。',
			ansList: ['马克思主义', '毛泽东思想', '中国特色社会主义', '科学发展观'],
			ans: '中国特色社会主义',
			multi: false
		},
		{
			txt: '十三届全国人大一次会议通过的宪法修正案，把（   ）载入宪法，实现了国家指导思想的与时俱进。',
			ansList: ['习近平新时代中国特色社会主义思想', '中国特色社会主义', '人类命运共同体', '社会主义民主'],
			ans: '习近平新时代中国特色社会主义思想',
			multi: false
		},
		{
			txt: '新时代中国特色社会主义思想，明确中国特色社会主义最本质的特征是（   ）。',
			ansList: ['“五位一体”总体布局', '人民利益为根本出发点', '中国共产党领导', '人民当家作主'],
			ans: '中国共产党领导',
			multi: false
		},
		{
			txt: '从全面建成小康社会到基本实现现代化，再到全面建成（   ），是新时代中国特色社会主义发展的战略安排。',
			ansList: ['创新型国家', '社会主义现代化强国', '社会主义现代化大国', '世界超级大国'],
			ans: '社会主义现代化强国',
			multi: false
		},
		{
			txt: '经过长期努力，中国特色社会主义进入了新时代，这是我国发展新的（     ）。',
			ansList: ['未来方向', '未来方位', '历史方向', '历史方位'],
			ans: '历史方位',
			multi: false
		},
		{
			txt: '（     ）是实现社会主义现代化、创造人民美好生活的必由之路。',
			ansList: ['中国特色社会主义道路', '中国特色社会主义理论体系', '中国特色社会主义制度', '中国特色社会主义文化'],
			ans: '中国特色社会主义道路',
			multi: false
		},
		{
			txt: '（     ）是当代中国发展进步的根本制度保障。',
			ansList: ['中国特色社会主义道路', '中国特色社会主义理论体系', '中国特色社会主义制度', '中国特色社会主义文化'],
			ans: '中国特色社会主义制度',
			multi: false
		},
		{
			txt: '坚持和发展中国特色社会主义，总任务是实现社会主义现代化和中华民族伟大复兴，在全面建成小康社会的基础上，分（     ）在本世纪中叶建成富强民主文明和谐美丽的社会主义现代化强国。',
			ansList: ['两步走', '三步走', '四步走', '五步走'],
			ans: '两步走',
			multi: false
		},
		{
			txt: '（     ）是社会主义民主政治的本质特征。',
			ansList: ['党的领导', '人民当家作主', '依法治国', '政治体制改革'],
			ans: '人民当家作主',
			multi: false
		},
		{
			txt: '我们要牢固树立社会主义生态文明观，推动形成（     ）现代化建设新格局，为保护生态环境作出我们这代人的努力。',
			ansList: ['人与自然和谐共生', '人与环境和谐发展', '人与自然和谐发展', '人与环境和谐共生'],
			ans: '人与自然和谐发展',
			multi: false
		},
		{
			txt: '党的十九届四中全会是在新中国成立70周年之际、在“两个一百年”奋斗目标历史交汇点上，召开的一次具有（     ）、里程碑意义的重要会议。',
			ansList: ['全局性', '关键性', '开创性', '创造性'],
			ans: '开创性',
			multi: false
		},
		//多选的 ===================================================================================================
		{
			txt: '《共产党宣言》发表以来的实践证明，马克思主义只有（    ），才能焕发出强大的生命力、创造力、感召力。 ',
			ansList: ['与本国国情相结合', '与时代发展同进步', '与人民群众共命运', '与统治阶级的意志相符合'],
			ans: ['与本国国情相结合', '与时代发展同进步', '与人民群众共命运'],
			multi: true
		},
		{
			txt: '19世纪欧洲三大空想社会主义者是指（     ）。',
			ansList: ['英国的欧文', '英国的斯密', '法国的傅立叶', '法国的圣西门'],
			ans: ['英国的欧文', '法国的傅立叶', '法国的圣西门'],
			multi: true
		},
		{
			txt: '苏联模式是苏维埃时期苏联共产党建设社会主义的一种组织体系和思想体系，（    ）。',
			ansList: ['它采用高度集中的政治经济体制进行社会主义建设', '它的关键要害在于树立个人崇拜', '它践踏了社会主义的民主和法制基本原则', '它坚持了无政府主义的民主集中制'],
			ans: ['它采用高度集中的政治经济体制进行社会主义建设', '它的关键要害在于树立个人崇拜', '它践踏了社会主义的民主和法制基本原则'],
			multi: true
		},
		{
			txt: '毛泽东认为，社会主义改造基本完成以后，在我们的面前有两类社会矛盾，这就是(     )，这是性质完全不同的两类矛盾。',
			ansList: ['基本矛盾', '敌我矛盾', '人民内部矛盾', '人与自然的矛盾', '生产和需要的矛盾'],
			ans: ['敌我矛盾', '人民内部矛盾'],
			multi: true
		},
		{
			txt: '社会主义建设道路初步探索的重要理论成果包括（    ）。',
			ansList: ['调动一切积极因素为社会主义事业服务的思想', '正确认识和处理社会主义社会矛盾的思想', '作出了工作重心转移到经济建设上来，实行改革开放的决策', '走中国工业化道路的思想'],
			ans: ['调动一切积极因素为社会主义事业服务的思想', '正确认识和处理社会主义社会矛盾的思想', '走中国工业化道路的思想'],
			multi: true
		},
		{
			txt: '1957年2月，毛泽东在《关于正确处理人民内部矛盾》的讲话中系统阐述的社会主义社会基本矛盾的理论的基本观点是（    ）。',
			ansList: ['社会主义社会的基本矛盾可以通过社会主义制度本身不断地得到解决', '社会主义社会基本矛盾是在人民利益根本一致基础上的非对抗性矛盾', '社会主义社会的基本矛盾具有基本适应.部分不适应的特点，又统一又斗争，推动社会主义不断完善巩固', '社会主义社会的基本矛盾仍然是生产关系和生产力，上层建筑和经济基础之间的矛盾'],
			ans: ['社会主义社会的基本矛盾可以通过社会主义制度本身不断地得到解决', '社会主义社会基本矛盾是在人民利益根本一致基础上的非对抗性矛盾', '社会主义社会的基本矛盾具有基本适应.部分不适应的特点，又统一又斗争，推动社会主义不断完善巩固', '社会主义社会的基本矛盾仍然是生产关系和生产力，上层建筑和经济基础之间的矛盾'],
			multi: true
		},
		{
			txt: '邓小平指出：“没有民主就没有社会主义，就没有社会主义现代化。”这一理论命题的涵义是（     ）。',
			ansList: ['民主建设是社会主义现代化建设的重要内容', '民主是社会主义的本质要求和内在属性', '民主建设是社会主义现代化建设的重要目标', '民主是社会主义现代化的政治保证'],
			ans: ['民主建设是社会主义现代化建设的重要内容', '民主是社会主义的本质要求和内在属性', '民主建设是社会主义现代化建设的重要目标', '民主是社会主义现代化的政治保证'],
			multi: true
		},
		{
			txt: '江泽民同志曾经指出党代表先进文化的前进方向，其中当代中国先进文化的前进方向是指（     ）。',
			ansList: ['以培育“四有”新人为目标', '以马列主义、毛泽东思想、邓小平理论为指导', '努力加强社会主义精神文明建设', '发展面向现代化、面向世界、面向未来的民族的、科学的、大众的有中国特色的社会主义文化'],
			ans: ['以培育“四有”新人为目标', '以马列主义、毛泽东思想、邓小平理论为指导', '努力加强社会主义精神文明建设', '发展面向现代化、面向世界、面向未来的民族的、科学的、大众的有中国特色的社会主义文化'],
			multi: true
		},
		{
			txt: '新时代坚持和发展中国特色社会主义的基本方略是（     ）。',
			ansList: ['坚持党对一切工作的领导，坚持以人民为中心，坚持全面深化改革', '坚持新发展理念，坚持人民当家作主，坚持全面依法治国', '坚持社会主义核心价值体系，坚持在发展中保障和改善民生，坚持人与自然和谐共生', '坚持总体国家安全观，坚持党对人民军队的绝对领导，坚持“一国两制”和推进祖国统一', '坚持推动构建人类命运共同体，坚持全面从严治党'],
			ans: ['坚持党对一切工作的领导，坚持以人民为中心，坚持全面深化改革', '坚持新发展理念，坚持人民当家作主，坚持全面依法治国', '坚持社会主义核心价值体系，坚持在发展中保障和改善民生，坚持人与自然和谐共生', '坚持总体国家安全观，坚持党对人民军队的绝对领导，坚持“一国两制”和推进祖国统一', '坚持推动构建人类命运共同体，坚持全面从严治党'],
			multi: true
		},
		{
			txt: '发展社会主义民主政治，建设社会主义政治文明，从民主制度的角度看，必须（    ）。 ',
			ansList: ['坚持和完善人民代表大会制度', '坚持和完善共产党领导的多党合作和政治协商制度', '坚持和完善民族区域自治制度', '健全基层民主管理制度'],
			ans: ['坚持和完善人民代表大会制度', '坚持和完善共产党领导的多党合作和政治协商制度', '坚持和完善民族区域自治制度', '健全基层民主管理制度'],
			multi: true
		},

	],
	// 青浦革命和发展史
	topic5: [
		{
			txt: '青浦最早的共产党员是练塘的（     ),二人合纂有《松柏文集》。	',
			ansList: ['蔡其忠、蔡其恕','邹铨、陈布雷','高尔松、高尔柏','吴志喜、吴开先'],
			ans: '高尔松、高尔柏',
			multi: false
		},
		{
			txt: '（     ）年，中国共产党建立青浦县党组织。	',
			ansList: ['1925','1926','1927','1928'],
			ans: '1927',
			multi: false
		},
		{
			txt: '1939年，中共江苏省委陆续派遣干部加强青浦抗日游击活动，先后展开战斗不下百次，其中规模和影响较大的有（     ）多次。	',
			ansList: ['10','20','30','40'],
			ans: '10',
			multi: false
		},
		{
			txt: '1927年9月，在青浦（     ）建立了本县第一个农村党支部。	',
			ansList: ['练塘','蒸淀','小蒸','莲盛'],
			ans: '小蒸',
			multi: false
		},
		{
			txt: '（     ）是青浦第一支建立的抗日武装。	',
			ansList: ['淞沪抗日军第三梯团','江南抗日义勇军','淞沪游击纵队第三支队','浙东游击纵队淞沪支队'],
			ans: '江南抗日义勇军',
			multi: false
		},
		{
			txt: '（     ）村在抗日战争时是青东抗日游击武装活动中心。	',
			ansList: ['陆家角','蟠龙','卫家角','毛家角'],
			ans: '陆家角',
			multi: false
		},
		{
			txt: '抗战爆发后，顾复生等共产党员在（     ）地区建立抗敌后援会，开创青东抗日游击区。	',
			ansList: ['观音堂','蟠龙镇','白马堂','白鹤'],
			ans: '观音堂',
			multi: false
		},
		{
			txt: '1939年8月，新四军（    ）义勇军副总指挥叶飞率部抵达青浦观音堂地区，指挥抗日斗争。	',
			ansList: ['华北抗日','华东抗日','江南抗日','皖北抗日	'],
			ans: '江南抗日',
			multi: false
		},
		{
			txt: '1945年4月，淞沪支部衡山大队在青浦小蒸地区（    ）伏击日军，击毙11名日军和1条军犬。	',
			ansList: ['庄前港村','庄行镇','泖甸村','东庄村'],
			ans: '庄前港村',
			multi: false
		},
		{
			txt: '陈云曾在位于青浦县城的（    ）学校学习珠算和记账。由于家境贫寒，一个月后就缀学回家。	',
			ansList: ['县立乙种商业','县立甲种商业','颜安小学','县立初等'],
			ans: '县立乙种商业',
			multi: false
		},
		{
			txt: '革命时期，陈云、（     ）、陆铨生领导发动了小蒸农民暴动。	',
			ansList: ['顾复生','吴志喜','高尔松','方强'],
			ans: '吴志喜',
			multi: false
		},
		{
			txt: '青浦解放是在1949年（     ）。	',
			ansList: ['4月21日','5月14日','10月1日','12月22日'],
			ans: '5月14日',
			multi: false
		},
		{
			txt: '1950年底至1952年底，青浦县报名参加中国人民志愿军有（    ）百多人。',
			ansList: ['五','六','七','八	'],
			ans: '七',
			multi: false
		},
		{
			txt: '1955年1月—1961年7月，陈云同志（    ）次到青浦家乡作农村调查，了解家乡农业生产、农村发展和农民群众的生活状况。	',
			ansList: ['1','2','3','4'],
			ans: '4',
			multi: false
		},
		{
			txt: '中共第七次全国代表大会有一名青浦代表（    ），原名顾德熙，又名张凡，时任中共青浦县工委委员，抗日后备队总指挥。	',
			ansList: ['顾复生','顾德欢','顾炳章','顾炎武'],
			ans: '顾炳章',
			multi: false
		},
		{
			txt: '1985年11月，青浦县第一家（    ）企业，由凤溪乡政府、上海文体用品进出口公司和日方企业三方共同投资创办的上海ACE箱包有限公司开业。',
			ansList: ['中外合资企业','外贸补偿企业','进口企业','科工贸一体化企业'],
			ans: '中外合资企业',
			multi: false
		},
		{
			txt: '1992年1月，青浦镇第一期全线竣工的筑堤工程是（     ）。',
			ansList: ['通坡塘','淀山湖','太浦河','大淀湖'],
			ans: '太浦河',
			multi: false
		},
		{
			txt: '1994年5月，（   ）特色蔬菜实验场成立，占地面积19.5万平方米，是上海市“菜篮子工程”重要基地之一？',
			ansList: ['赵巷','赵屯','莲盛','白鹤'],
			ans: '赵巷',
			multi: false
		},
		{
			txt: '青浦区撤乡建镇工作是（    ）年结束的。',
			ansList: ['1994','1995','1996','1997'],
			ans: '1996',
			multi: false
		},
		{
			txt: '青浦撤县建区是（    ）年。	',
			ansList: ['1998','1999','2002','2001'],
			ans: '1999',
			multi: false
		},
		{
			txt: '2012年12月3日，上海市第一张农村集体土地所有权证在（     ）镇中新村颁发，标志着农村集体土地从此开始具有法律意义上的合法身份。',
			ansList: ['金泽','徐泾','重固','练塘'],
			ans: '重固',
			multi: false
		},
		{
			txt: '青浦有（    ）个天然湖泊。',
			ansList: ['21','22','23','24'],
			ans: '21',
			multi: false
		},
		{
			txt: '（    ）年12月，国家会展中心全面竣工。	',
			ansList: ['2011','2012','2013','2014'],
			ans: '2014',
			multi: false
		},
		{
			txt: '位于青浦出口加工区的上海青浦民用航空产业园于（    ）年启动开发。',
			ansList: ['2010年','2011年','2012年','2013年'],
			ans: '2011年',
			multi: false
		},
		{
			txt: '2016年10月，（    ）入选住建部公布的第一批中国特色小镇。',
			ansList: ['朱家角镇','华新镇','赵巷镇','白鹤镇'],
			ans: '朱家角镇',
			multi: false
		},
		{
			txt: '1992年7月，(     ) 在蒸淀乡创立并启动，是华东地区首家私营经济开发区，形成了一整套完善有效的招商、服务和管理模式，吸引中外众多私营企业落户。',
			ansList: ['上海青浦新城经济开发区','上海富民私营经济开发区','上海淀山湖私营经济开发区','上海青浦工业园区'],
			ans: '上海富民私营经济开发区',
			multi: false
		},
		{
			txt: '为推进长三角一体化发展，上海将紧扣“一体化”“高质量”这两大关键词，紧抓三个重点区域建设。其中第一个就是在江苏苏州吴江、浙江嘉善和上海青浦建设长三角（    ）一体化发展示范区。',
			ansList: ['社会治理','生态绿色','创新产业','交通设施'],
			ans: '生态绿色',
			multi: false
		},
		{
			txt: '上海轨道交通17号线全长(    )公里，从虹桥火车站到东方绿舟站共设(     )站点。',
			ansList: ['35,10','25,10','35,13','25,13'],
			ans: '35,13',
			multi: false
		},
		{
			txt: '青浦区五届区委九次会议提出，我们要紧紧围绕巩固和提升（    ）优势，实现“五大跨越”.',
			ansList: ['区位优势、战略优势、人才优势','战略优势、功能优势、区位优势','战略优势、功能优势、政策优势','功能优势、地理优势、政策优势'],
			ans: '战略优势、功能优势、区位优势',
			multi: false
		},
		{
			txt: '青浦区五届区委九次全会提出，要聚焦国家战略，打造功能组团，青东要打造______经济组团，青西要打造_____经济组团，新城要打造______经济组团。(     )',
			ansList: ['科创和商贸、智造和创新、绿色和服务','商贸和服务、科创和智造、创新和服务','科创和商贸、绿色和创新、智造和服务','创新和商贸、科创和智造、绿色和服务'],
			ans: '科创和商贸、绿色和创新、智造和服务',
			multi: false
		},
		// 多选 =======================================================================================================================================
		{
			txt: '20世纪80年代，在农村经济体制改革和对内搞活、对外开放政策的推动下，青浦全县乡村工业积极发展横向经济联合，创办城乡联营企业，出现了（    ）形式的“联营热”。',
			ansList: ['生产联营','产销联营','技术联营','资金联营'],
			ans: ['生产联营','产销联营','技术联营','资金联营'],
			multi: true
		},
		{
			txt: '至1985年底，青浦县形成了以青浦镇为中心，沟通各乡镇的公路网，全县有公交客运线12条，线路总里程347.45公里，当时设的车站有（     ）。',
			ansList: ['青浦总站','朱家角','练塘','白鹤'],
			ans: ['青浦总站','朱家角','练塘'],
			multi: true
		},
		{
			txt: '20世纪80年代会用于田间的劳作机器有（     ）。',
			ansList: ['拖拉机','拔秧机','插秧机','收秧机'],
			ans: ['拖拉机','拔秧机','插秧机'],
			multi: true
		},
		{
			txt: '2007年6月5日，（     ）被列入上海市第一批非物质文化遗产。',
			ansList: ['青浦田山歌','青浦宣卷','青浦阿婆茶','青浦评弹'],
			ans: ['青浦田山歌','青浦宣卷','青浦阿婆茶'],
			multi: true
		},
		{
			txt: '目前，青浦（    ）镇被命名为“中国历史文化名镇”。',
			ansList: ['朱家角镇','练塘镇','金泽镇','赵巷镇'],
			ans: ['朱家角镇','练塘镇','金泽镇'],
			multi: true
		},
		{
			txt: '2016年10月18日，上海青西郊野公园一期正式对外开园，围绕大莲湖形成（    ）三大功能区。',
			ansList: ['生态保育功能区','水乡农田示范区','渔村休闲体验区','露营烧烤活动区'],
			ans: ['生态保育功能区','水乡农田示范区','渔村休闲体验区'],
			multi: true
		},
		{
			txt: '2016年12月29日，金泽水库正式投入使用，水库日供水规模351万立方米，为（    ）区提供原水。',
			ansList: ['青浦','金山','闵行','奉贤','松江'],
			ans: ['青浦','金山','闵行','奉贤','松江'],
			multi: true
		},
		{
			txt: '青浦区五届区委九次全会肯定了青浦的发展，从追赶式发展迈入全面跨越式高质量发展新阶段，实现了（      ）。',
			ansList: ['城市形象大转变','城市规划大转变','城市格局大转变','城市治理大转变','城市生态大转变'],
			ans: ['城市形象大转变','城市格局大转变','城市治理大转变','城市生态大转变'],
			multi: true
		},
		{
			txt: '2017年6月，青浦区召开“一园三区”党政干部会议，会议宣布区委、区政府同意《青浦区“一园三区”一体化改革总体方案》，该方案决定对（    ）采取“机构一体、三块牌子、一套班子”的运作模式，进行统一管理。',
			ansList: ['上海青浦工业园区发展（集团）有限公司','上海张江高新技术产业开发区青浦园区（集团）有限公司','上海青浦出口加工区开发有限公司','上海青浦现代农业园区发展有限公司'],
			ans: ['上海青浦工业园区发展（集团）有限公司','上海张江高新技术产业开发区青浦园区（集团）有限公司','上海青浦出口加工区开发有限公司'],
			multi: true
		},
		{
			txt: '当前，青浦承载着（    ）两大国家战略，迎来了历史性的发展机遇。',
			ansList: ['中国国际进口博览会','打造上海自贸区新片区','设立科创板并试点注册制','长三角一体化发展'],
			ans: ['中国国际进口博览会','长三角一体化发展'],
			multi: true
		}
	],
};
_question.fight = (() => {
	const questions = [ ..._question.topic1, ..._question.topic2, ..._question.topic3, ..._question.topic4, ..._question.topic5 ];
	questions.sort(function(a, b) {
		return Math.random()>.5 ? -1 : 1;
	});
	return questions;
})();
_question.ypdj = [
	{
		txt: '盈浦街道“四史”教育基地共有几个',
		ansList: ['4', '5', '6', '7'],
		ans: '7',
		multi: false
	},
	{
		txt: '以下哪个不是盈浦街道实验小学御澜湾校区“四史”共享基地的特色主题',
		ansList: ['美术', '剪纸', '篆刻', '书法'],
		ans: '剪纸',
		multi: false
	},
	{
		txt: '盈浦街道“四史”教育基地是哪7个',
		ansList: [
			'社区党群服务中心、文化活动中心、电信城厢分局、实验小学御澜湾校区、庆新社区、盈中社区、上善广场”上善驿“',
			'社区党群服务中心、文化活动中心、电信城厢分局、实验小学御澜湾校区、庆新社区、盈中社区、梦蝶岛',
			'社区事务受理中心、文化活动中心、电信城厢分局、实验小学御澜湾校区、庆新社区、盈中社区、上善广场”上善驿“',
			'社区党群服务中心、文化活动中心、电信城厢分局、实验小学御澜湾校区、庆华社区、盈中社区、上善广场”上善驿“'
		],
		ans: '社区党群服务中心、文化活动中心、电信城厢分局、实验小学御澜湾校区、庆新社区、盈中社区、上善广场”上善驿“',
		multi: false
	},
	{
		txt: '哪所学校与盈浦街道共建了“四史”教育共享基地？',
		ansList: [ '实验中学', '实验小学御澜湾校区', '庆华小学', '尚美中学' ],
		ans: '实验小学御澜湾校区',
		multi: false
	},
	{
		txt: '盈浦街道哪两个社区建立“四史”教育基地',
		ansList: [ '绿舟社区和怡澜社区', '庆华社区和盈中社区', '庆新社区和盈中社区', '双桥社区和浩泽社区' ],
		ans: '庆新社区和盈中社区',
		multi: false
	},
	{
		txt: '盈浦街道社区文化活动中心“四史”教育基地的特色内容是',
		ansList: [ '红色长廊', '“四史”楼梯年鉴', '“朗读亭”有声阅读', '党的光辉历程' ],
		ans: '“朗读亭”有声阅读',
		multi: false
	},
	{
		txt: '盈浦街道社区党群服务中心“四史”教育基地的特色内容是什么？',
		ansList: [
			'红色长廊、上海如何“先行一步，学深一步”主题展板、党的光辉历程、“一史一室”',
			'红色长廊、上海如何“先行一步，学深一步”主题展板、党的光辉历程、“朗读亭”有声阅读',
			'红色长廊、上海如何“先行一步，学深一步”主题展板、党的光辉历程、“四史”楼梯年鉴',
			'红色长廊、上海如何“先行一步，学深一步”主题展板、党的光辉历程、追寻乡愁'
		],
		ans: '红色长廊、上海如何“先行一步，学深一步”主题展板、党的光辉历程、“一史一室”',
		multi: false
	}
];
export const question = _question;

export const getQuestionByTopicCatId = (cid, num, multiNum) => {
	let topicList = _question[`topic${cid}`] || [];
	if (!topicList || topicList.length <= 0) return [];

	const oriMultiArr = [], oriArr = [];
	topicList.map(item => {
		if (item.multi == true) {
			oriMultiArr.push(item);
		} else if (item.multi == false) {
			oriArr.push(item);
		}
	});

	const multiArr = [], arr = [];
	if (num && num > 0) {
		for (let i = 0; i < num; i++) {
			const idx = Utils.initRandom(num);
			arr.push(oriArr.splice(idx, 1)[0]);
		}
	}
	if (multiNum && multiNum > 0) {
		for (let i = 0; i < multiNum; i++) {
			const idx = Utils.initRandom(multiNum);
			multiArr.push(oriMultiArr.splice(idx, 1)[0]);
		}
	}

	return [...arr, ...multiArr];
};

export const order = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'];

export const getFightQuestion = (num, multiNum, extraKey = '') => {
	let topicList = _question.fight || [];
	if(extraKey && _question[extraKey] && _question[extraKey].length > 0) {
		topicList = [ ...topicList, ..._question[extraKey] ];
	}
	if (!topicList || topicList.length <= 0) return [];
	const oriMultiArr = [], oriArr = [];
	topicList.map(item => {
		if (item.multi == true) {
			oriMultiArr.push(item);
		} else if (item.multi == false) {
			oriArr.push(item);
		}
	});

	const multiArr = [], arr = [];
	if (num && num > 0) {
		for (let i = 0; i < num; i++) {
			const idx = Utils.initRandom(oriArr.length);
			arr.push(oriArr.splice(idx, 1)[0]);
		}
	}
	if (multiNum && multiNum > 0) {
		for (let i = 0; i < multiNum; i++) {
			const idx = Utils.initRandom(oriMultiArr.length);
			multiArr.push(oriMultiArr.splice(idx, 1)[0]);
		}
	}

	return [...arr, ...multiArr];
};
