/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';
import moment from 'moment';

import Page from '../components/page';

import * as Question from '../common/question';
import * as CONST from '../common/const';
import * as Utils from '../common/utils';
import * as Request from '../common/request';

import './daka.less';

const COUNTDOWN_LIMIT = 60 * 60;  // 限时学习——1个小时
let timer = null;

const PageWrapper = props => {
	const [count, setCount] = useState(1);
	const [step, setStep] = useState(1);
	const [questions, setQuestions] = useState([]);
	const [topic, setTopic] = useState({});
	const [question, setQuestion] = useState({});
	const [countDown, setCountDown] = useState(0);
	const [userAns, setUserAns] = useState({});

	/**
	 * 获取专题
	 */
	const getTopic = () => {
		// const {topicId} = props.match.params;
		// const topic = CONST.projectConf.getTopicById(topicId);
		let topic = moment().format('YYYYMMDD');
		const obj = Utils.getSearchByKey('obj');
		if(obj) {
			topic = obj + '-' + topic;
		}
		setTopic(topic);
	};
	const topicStart = async() => {
		await Request.get({
			url: '/topic/start'
		});

		getTopic();
	};
	useEffect(() => {
		topicStart();
	}, [123]);

	/**
	 * 获取题目列表
	 */
	const getQuestions = () => {
		const obj = Utils.getSearchByKey('obj');
		const questions = Question.getFightQuestion(8, 2, obj);
		questions.map(question => {
			question.ansList = question && question.ansList && question.ansList.map(item => ({
				txt: item,
				selected: false,
				right: question.multi && question.ans.includes(item) || question.ans == item
			})) || [];
			return question;
		});
		setQuestions(questions);
	};
	const doCountDown = (sec = 0) => {
		clearTimeout(timer);
		timer = null;

		if (sec >= COUNTDOWN_LIMIT) {
			submit();
			return;
		}
		setCountDown(sec + 1);
		timer = setTimeout(() => {
			doCountDown(sec + 1);
		}, 1000);
	};
	useEffect(() => {
		getQuestions();
		doCountDown();
	}, [123]);

	/**
	 * 获取当前题目
	 */
	const getQuestion = () => {
		const question = questions[step - 1] || {};
		setQuestion(question);
	};
	useEffect(() => {
		getQuestion();
	}, [questions, step]);

	const submit = async() => {
		let number = 0, score = 0;
		Object.keys(userAns).map(key => {
			const target = userAns[key];
			if (target.question.multi == true) {
				let numberFlag = false;
				target.ansList.map(item => {
					if (item && item.right) {
						numberFlag = item.selected;
					}
				});
				if (numberFlag) {
					number += 1;
				}
			} else {
				target.ansList.map(item => {
					if (item && item.right && item.selected) {
						number += 1;
					}
				});
			}
		});
		score = number * 10;

		const result = await Request.post({
			url: '/topic/end',
			data: {
				category: topic,
				number,
				total_score: score,
				spend_time: countDown
			}
		});
		if (!result) return;

		if(score >= 60) {
			Utils._alert(`恭喜你打卡成功，获得分数 ${score}`);
		}else{
			Utils._alert(`打卡失败，当前得分 ${score}`);
		}

		setTimeout(() => {
			const obj = Utils.getSearchByKey('obj');
			if(obj) {
				props.history.push('/history?obj=' + obj);
			}else{
				props.history.push('/history');
			}
		}, 800);
	};

	const checkAllVerify = () => {
		const ansList = Object.keys(userAns);
		if(ansList.length != questions.length) return false;

		let checkAll = true;
		for(let i=0, len = ansList.length; i<len; i++) {
			const target = userAns[ansList[i]];
			if(!target.showAns) {
				checkAll = false;
				break;
			}
		}

		return checkAll;
	};

	const nextStep = () => {
		return {
			add: () => {
				if(step < questions.length) {
					setStep(step + 1);
					return false;
				}
			},
			minus: () => {
				if(step - 1 <= 0) return;
				setStep(step - 1);
			}
		}
	};

	return (
		<Page title="知识问答">
			<section className="topic-answer-wrapper">
				<div className="countdown-wrapper">
					<div className="countdown"><em style={{width: ((COUNTDOWN_LIMIT - countDown) / COUNTDOWN_LIMIT * 100) + '%'}}/></div>
					<span>倒计时 {Utils.secondFormat(COUNTDOWN_LIMIT - countDown, COUNTDOWN_LIMIT - countDown > 60 ? 'mm分ss秒' : 'ss秒')}</span>
				</div>
				<div className="question-wrapper">
					<div className="qheader">
						<div className="title">{ question.multi ? '多选题' : '单选题' }</div>
						<span className="order"><i>{Math.min(step, questions.length)}</i>/{questions.length}</span>
					</div>
					<div className="qcontent">
						<div className="quesiton">{question.txt}</div>
						<div className="answers">
							{
								question.ansList && question.ansList.length > 0 && question.ansList.map((item, idx) => {
									let cls = 'item';
									if (userAns[question.txt]) {
										cls += item.selected ? ' selected' : '';
									}
									if (userAns[question.txt] && userAns[question.txt].showAns) {
										cls += item.right ? ' right' : ' wrong';
									}

									return (
										<div key={idx} className={cls} onClick={() => {
											if (userAns[question.txt] && userAns[question.txt].showAns) return;

											if (question.multi == true) {
												question.ansList[idx].selected = !question.ansList[idx].selected;
											} else {
												question.ansList.map(q => {
													q.selected = false;
												});
												question.ansList[idx].selected = true;
											}
											userAns[question.txt] = userAns[question.txt] || {};
											userAns[question.txt] = {
												question,
												ansList: question.ansList
											};
											setUserAns(userAns);

											setCount(count + 1);
											// setQuestion(question);
										}}>
											<label>{Question.order[idx]}.</label><span>{item.txt}</span>
										</div>
									)
								})
							}
						</div>
					</div>
					<div className={`qsubmit${ userAns[question.txt] && userAns[question.txt].showAns ? ' disabled' : '' }`} onClick={() => {
						if(!userAns[question.txt]) {
							Utils._alert('请选择答案');
							return;
						}
						if (userAns[question.txt] && userAns[question.txt].showAns) return;

						userAns[question.txt] = userAns[question.txt] || {};
						userAns[question.txt].showAns = true;
						setUserAns(userAns);

						let isRight = false;
						question.ansList.map(item => {
							if(item.right) {
								isRight = item.selected;
							}
						});

						if(isRight) {
							setTimeout(() => {
								nextStep().add();
							}, 2000);
						}
					}}>确认</div>
					<div className="submit-ctrls">
						<div className={`ctrl-item${step - 1 <= 0 ? ' disabled' : ''}`} onClick={() => {
							nextStep().minus();
						}}>上一题</div>
						<div className={`ctrl-item${step >= questions.length && !checkAllVerify() ? ' disabled' : ''}`} onClick={() => {
							nextStep().add();

							/**
							 * submit answers
							 */
							if(step < questions.length) return;
							if(!checkAllVerify()) {
								Utils._alert('请完成所有题目学习');
								return;
							}
							submit();
						}}>{step >= questions.length ? '完成学习' : '下一题'}</div>
					</div>
				</div>
			</section>
		</Page>
	);
};

export default PageWrapper;
