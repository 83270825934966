/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';

import Page from '../components/page';

import * as Question from '../common/question';
import * as Utils from '../common/utils';
import * as CONST from '../common/const';
import * as Request from '../common/request';

import './against-answer.less';

//images

const COUNTDOWN_LIMIT = 20;
let timer = null;
let loopTimer = null;
let selectedTime = null;
let socketInfo = {};

const PageWrapper = props => {
	const [count, setCount] = useState(1);

	/**
	 * 获取题目列表
	 */
	const [questions, setQuestions] = useState([]);
	const [questionsLength, setQuestionsLength] = useState(0);
	const getQuestions = async() => {
		const {fid} = props.match.params;
		const result = await Request.post({
			url: '/against/flight',
			data: {
				id: fid,
				radio: 5,
				multiple: 0
			}
		});
		if (!result) return;
		const questions = result.data || [];
		questions.map(question => {
			question.ansList = question && question.ansList && question.ansList.map(item => ({
					txt: item,
					selected: false,
					right: question.multi && question.ans.includes(item) || question.ans == item
				})) || [];
			return question;
		});
		setQuestions(questions);
		setQuestionsLength(questions.length);
		return questions;
	};
	useEffect(() => {
		getQuestions();
	}, [123]);

	const [userAns, setUserAns] = useState({});
	const [countDown, setCountDown] = useState(COUNTDOWN_LIMIT);
	const [step, setStep] = useState(1);
	const [question, setQuestion] = useState({});
	const getQuestion = () => {
		const question = questions[step - 1] || {};
		setQuestion(question);
	};
	const doCountDown = (sec) => {
		clearTimeout(timer);
		timer = null;

		if (sec <= 0) {
			setStep(step + 1);
			socketInfo.answers = [];
			return;
		}
		setCountDown(sec - 1);
		timer = setTimeout(() => {
			doCountDown(sec - 1);
		}, 1000);
	};
	const submit = async() => {
		const {fid} = props.match.params;

		const initiator_score = socketInfo.initiator_score || 0;
		const challenger_score = socketInfo.challenger_score || 0;
		const initiator_number = socketInfo.initiator_number || 0;
		const challenger_number = socketInfo.challenger_number || 0;

		const result = await Request.post({
			url: '/against/end',
			data: {
				id: fid,
				initiator: pker && pker.initiator,
				challenger: pker && pker.challenger,
				initiator_score: initiator_score,
				initiator_integral: initiator_score,
				initiator_number: initiator_number,
				challenger_score: challenger_score,
				challenger_integral: challenger_score,
				challenger_number: challenger_number,
				winner: initiator_score > challenger_score ? pker.initiator : pker.challenger,
			}
		});
		if (!result) return;

		props.history.push(`/against/${fid}/result?${[
			`initiator_score=${initiator_score}`,
			`initiator_number=${initiator_number}`,
			`challenger_score=${challenger_score}`,
			`challenger_number=${challenger_number}`
		].join('&')}`);
	};
	useEffect(() => {
		if (questionsLength > 0 && step > questionsLength) {
			submit();
			return;
		}
		getQuestion();
		doCountDown(COUNTDOWN_LIMIT);
	}, [questions, step]);

	/**
	 * 开始对战，获取本次对抗信息
	 */
	const [pker, setPker] = useState({});
	const startPK = async() => {
		const {fid} = props.match.params;
		const result = await Request.get({
			url: '/against/start',
			data: {
				id: fid
			}
		});
		if (!result) return;
		setPker(result.data || {});
	};
	useEffect(() => {
		startPK();
	}, [123]);

	/**
	 * 对战过程 - 对战客户端同步
	 */
	const syncPKInfo = (question = {}, idx, callback) => {
		let initiator_score = socketInfo.initiator_score || 0;
		let challenger_score = socketInfo.challenger_score || 0;
		let initiator_number = socketInfo.initiator_number || 0;
		let challenger_number = socketInfo.challenger_number || 0;
		const _addScore = () => {
			const userInfo = Utils.user.getInfo();
			if (userInfo && userInfo.id == pker.initiator) {
				initiator_number += 1;
				initiator_score += 10;
			} else if (userInfo && userInfo.id == pker.challenger) {
				challenger_number += 1;
				challenger_score += 10;
			}
		};

		const answers = socketInfo.answers || [];
		const userInfo = Utils.user.getInfo() || {};
		if(userInfo.id && !answers.includes(userInfo.id)) {
			answers.push(userInfo.id);
		}

		const _freshPk = async(initiatorScore = 0, challengerScore = 0, initiatorNumber = 0, challengerNumber = 0, answers = []) => {
			const { fid } = props.match.params;
			await Request.post({
				url: '/against/refresh',
				data: {
					id: fid,
					initiator: pker.initiator,
					challenger: pker.challenger,
					initiator_score: initiatorScore,
					challenger_score: challengerScore,
					initiator_number: initiatorNumber,
					challenger_number: challengerNumber,
					answers
				}
			});
		};

		if(question.multi) {
			clearTimeout(selectedTime);
			selectedTime = null;

			let ansCnt = 0;
			question.ansList.map(item => {
				if(item && item.selected) {
					if(question.ans.includes(item.txt)) {
						ansCnt += 1;
					}
				}
			});

			if(question.ans.length == ansCnt) {
				selectedTime = setTimeout(() => {
					_addScore();
					_freshPk( initiator_score, challenger_score, initiator_number, challenger_number, answers);
					return;
				}, 2500);
			}
		}else{
			if (question.ansList[idx].right == true && question.ansList[idx].selected == true) {
				_addScore();
			}
		}
		_freshPk( initiator_score, challenger_score, initiator_number, challenger_number, answers);
	};
	const selectedHandle = (idx) => {
		if (question.multi == false && !!userAns[question.txt]) return;

		if (question.multi == true) {
			question.ansList[idx].selected = !question.ansList[idx].selected;
			clearTimeout(selectedTime);
			selectedTime = null;
		} else {
			question.ansList[idx].selected = true;
		}
		userAns[question.txt] = userAns[question.txt] || {};
		userAns[question.txt] = {
			question,
			ansList: question.ansList
		};
		setUserAns(userAns);

		syncPKInfo(question, idx);
	};

	const stepLoop = () => {
		clearTimeout(loopTimer);
		loopTimer = null;
		if(socketInfo.answers && socketInfo.answers.length == 2){
			setTimeout(() => {
				setStep(step + 1);
				socketInfo.answers = [];
			}, question.multi ? 1800 : 800);
		}
		loopTimer = setTimeout(() => {
			stepLoop();
		}, 800);
	};
	useEffect(() => {
		stepLoop();
	}, [question]);

	const socketInit = () => {
		const {fid} = props.match.params;
		const socket = null; // window.io(CONST.wsApi);

		if(!socket) return;
		socket.on(`event-channel-${fid}`, function (data) {
			let obj = JSON.parse(data.data);
			socketInfo = {
				...socketInfo,
				...obj
			};
		});
	};
	useEffect(() => {
		socketInit();
	}, [123]);

	const initiator_score = socketInfo.initiator_score || 0;
	const challenger_score = socketInfo.challenger_score || 0;
	return (
		<Page title="知识竞赛场">

			<section className="against-answer-wrapper">
				<div className="userinfo">
					<div className="avatar">
						<div className="img" style={{backgroundImage: `url(${pker && pker.initiator_avatar || CONST.AVATAR_DEFAULT})`}}/>
						<span style={{marginLeft: 10}}>{pker && pker.initiator_name}</span>
					</div>
					<div className="timer">
						{/*<span className="bg"/>*/}
						{/*<span className="bg2"/>*/}
						<span className="time">{countDown}s</span>
					</div>
					<div className="avatar">
						<span style={{marginRight: 10}}>{pker && pker.challenger_name}</span>
						<div className="img" style={{backgroundImage: `url(${pker && pker.challenger_avatar || CONST.AVATAR_DEFAULT})`}}/>
					</div>
				</div>
				<div className="question-wrapper" style={{height: window.innerHeight - 448}}>
					<div className="aqheader">第<i>{Math.min(step, questionsLength)}</i>/{questionsLength}题{question.muti == true ? '【多选】' : ''}</div>
					<div className="aqcontent">{question.txt}</div>
				</div>
				<div className="question-answer">
					<div className="user-point">
						<span>{initiator_score}</span>
						<div className="total">
							<em style={{height: (initiator_score / questionsLength * 10) * 100 / 100 + '%'}}/>
						</div>
					</div>
					<div className="answer-wrap">
						{
							question.ansList && question.ansList.length > 0 && question.ansList.map((item, idx) => {
								let cls = 'item';
								if (userAns[question.txt]) {
									cls += item.selected ? ' selected' : '';
									if (question.multi == true) {
										if (item.selected) {
											cls += item.right ? ' right' : ' wrong';
										}
									} else if (question.multi == false) {
										cls += item.right ? ' right' : '';
										cls += item.selected && !item.right ? ' wrong' : '';
									}
								}

								return (
									<div key={idx} className={cls} onClick={() => {
										selectedHandle(idx);

										setCount(count + 1);
									}}>
										<label>{Question.order[idx]}.</label><span>{item.txt}</span>
									</div>
								)
							})
						}
					</div>
					<div className="user-point">
						<span>{challenger_score}</span>
						<div className="total">
							<em style={{height: (challenger_score / questionsLength * 10) * 100 / 100 + '%'}}/>
						</div>
					</div>
				</div>
			</section>
		</Page>
	);
};

export default PageWrapper;
