/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

import Page from '../components/page';

import * as Utils from '../common/utils';
import * as CONST from '../common/const';
import * as Request from '../common/request';

import './home.less';

const IMG_HOME_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/home-banner.png';
const IMG_STUDY_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/study-banner.png';
const IMG_AGAINST_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/against-banner.png';
const IMG_CERT_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/cert-banner.jpg';
const IMG_ORDER_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/order-banner.jpg';

let timer = null;
const PageWrapper = props => {
	const [registerFlag, setRegisterFlag] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const initial = () => {
		clearTimeout(timer);
		timer = null;
		const userInfo = Utils.user.getInfo();
		if (!userInfo) {
			timer = setTimeout(() => {
				initial();
			}, 333);
		}
		if (userInfo && userInfo.id) {
			setUserInfo(userInfo);
			setRegisterFlag(false);
		} else {
			setRegisterFlag(true);
		}
	};
	useEffect(() => {
		initial()
	}, [123]);

	const [certCnt, setCertCnt] = useState(0);
	const getCertList = async() => {
		let list = window.sessionStorage.getItem('certs');
		list = list && typeof list == 'string' ? JSON.parse(list) : null;
		if(!list) {
			let result = await Request.get({
				url: '/topic/category-lists'
			});
			list = !result ? [] : result.data;
			if(!result) {
				list = [];
			}else{
				list = result.data;
				if(list && list.length) {
					window.sessionStorage.setItem('certs', JSON.stringify(list));
				}
			}
		}

		if(!list) list = [];
		const titleList = list.map(item => item.category);
		let cnt = 0;
		CONST.projectConf.topics.map(item => {
			if (titleList.includes(item.title)) {
				cnt += 1;
			}
		});
		setCertCnt(cnt);
	};
	useEffect(() => {
		getCertList();
	}, [userInfo]);

	const [registerForm, setRegisterForm] = useState({});
	const submit = async() => {
		// 信息校验
		if (!registerForm.street || registerForm.street == 'none') return Utils._alert('请选择街镇');
		if (!registerForm.department || registerForm.department == 'none') return Utils._alert('请选择部门');
		if (!registerForm.name) return Utils._alert('请输入姓名');
		if (!CONST.regRules.mobile.test(registerForm.phone)) return Utils._alert('请输入合法手机号码');

		let userInfo = Utils.user.getInfo();
		if (userInfo && userInfo.id) {
			const result = await Request.post({
				url: '/user/update',
				data: {
					...registerForm
				}
			});
			if (!result) return;

			userInfo = { ...userInfo, ...registerForm };
		}else{
			const result = await Request.post({
				url: '/user/login',
				data: {
					...registerForm,
					openid: userInfo.openid,
					avatar: userInfo.avatar,
					nickname: userInfo.nickname
				}
			});
			if (!result) return;

			const token = `${result.data.token_type} ${result.data.access_token}`;
			window.sessionStorage.setItem('token', token);
			userInfo = result.data.user;
		}

		Utils.user.setInfo(userInfo);
		setUserInfo(userInfo);
		setRegisterFlag(false);
		setRegisterForm({});
	};

	return (
		<Page title='"四史"学习教育'>
			<section className="home-wrapper">
				<div className="userinfo">
					<div className="avatar" onClick={() => {
						setRegisterForm({
							street: userInfo && userInfo.street || 'none',
							department: userInfo && userInfo.department || 'none',
							name: userInfo && userInfo.name || '',
							phone: userInfo && userInfo.phone || ''
						});
						setRegisterFlag(true);
					}}>
						<div className="img" style={{backgroundImage: `url(${userInfo && userInfo.avatar || CONST.AVATAR_DEFAULT})`}}/>
						<span>{ userInfo && userInfo.name || '未登录' } ></span>
					</div>
					<div className="point">
						<div className="img"/>
						<div className="point-value">
							<span className="title">专题学习成就</span>
							<span>获得徽章数 · { certCnt || 0 }</span>
						</div>
					</div>
				</div>
				<div className="banner" style={{backgroundImage: `url(${IMG_HOME_BANNER})`}}/>
				<div className="entrance">
					<Link className="item" style={{backgroundImage: `url(${IMG_STUDY_BANNER})`}} to="/topics"/>
					<Link className="item" style={{backgroundImage: `url(${IMG_AGAINST_BANNER})`}} to="/against/mapping"/>
				</div>
				<div className="entrance2">
					<Link to="/certs" style={{backgroundImage: `url(${IMG_CERT_BANNER})`}} className="item"></Link>
					<Link to="/against/order" style={{backgroundImage: `url(${IMG_ORDER_BANNER})`}} className="item"></Link>
				</div>
			</section>

			{ registerFlag && <div className="mask"></div> }
			{
				registerFlag && <div className="register-wrapper">
					<div className="title">用户登录</div>
					<div className="form-wrap">
						<div className="form-item">
							<select
								onChange={e => {
									const value = e.target.value;
									setRegisterForm({...registerForm, street: value});
								}}
								placeholder="所属党委（党工委、党组）"
							  defaultValue={userInfo && userInfo.street || undefined}
							>
								<option value="none">- 所属党委（党工委、党组） -</option>
								{
									CONST.projectConf.streets.map((item, idx) => <option key={idx} value={item}>{item}</option>)
								}
							</select>
						</div>
						<div className="form-item">
							<input
								placeholder="请输入所属党支部"
								onChange={e => {
									const value = e.target.value;
									setRegisterForm({...registerForm, department: value});
								}}
								defaultValue={userInfo && userInfo.department || undefined}
							/>
						</div>
						<div className="form-item">
							<input
								placeholder="姓名"
								onChange={e => {
									const value = e.target.value;
									setRegisterForm({...registerForm, name: value});
								}}
								defaultValue={userInfo && userInfo.name || undefined}
							/>
						</div>
						<div className="form-item">
							<input
								placeholder="手机号"
								maxLength="11"
								onChange={e => {
									const value = e.target.value;
									setRegisterForm({...registerForm, phone: value});
								}}
								defaultValue={userInfo && userInfo.phone || undefined}
							/>
						</div>
					</div>
					<div className="formbtn-wrap">
						<span className="submit" onClick={submit}>确定</span>
						<span className="cancel" onClick={() => {
							setRegisterFlag(false);
							setRegisterForm({});
						}}>取消</span>
					</div>
				</div>
			}

			{
				(!userInfo || !userInfo.id) && <div className="home-wrapper-cover" onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();

					setRegisterFlag(true);
					if (!userInfo || !userInfo.id) {
						setRegisterFlag(true);
						setRegisterForm({});
					}
				}}/>
			}
		</Page>
	);
};

export default PageWrapper;
