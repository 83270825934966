/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';
import moment from 'moment';

import Page from '../components/page';

import * as CONST from '../common/const';
import * as Request from '../common/request';
import * as Utils from '../common/utils';

import './daka-history.less';

import IMG_ACCEPT from '../common/images/accept.png';
import IMG_BANNER from '../common/images/cbanner.jpeg';

const PageWrapper = props => {
	const [list, setList] = useState([]);
	const getList = async() => {
		const obj = Utils.getSearchByKey('obj');
		const data = {};
		if(obj) {
			data.all = obj;
		}
		const result = await Request.get({
			url: '/topic/category-lists',
			data
		});
		if(!result) return;
		// const result = {"code":1,"data":[{"category":"\u515a\u53f2\u4e13\u9898\u7b2c\u4e00\u671f","total_score":70},{"category":"\u515a\u53f2\u4e13\u9898\u7b2c\u4e00\u671f","total_score":80},{"category":"\u515a\u53f2\u4e13\u9898\u7b2c\u4e00\u671f","total_score":90},{"category":"\u515a\u53f2\u4e13\u9898\u7b2c\u4e8c\u671f","total_score":90},{"category":"\u6539\u9769\u5f00\u653e\u53f2\u4e13\u9898\u7b2c\u4e00\u671f","total_score":80},{"category":"\u793e\u4f1a\u4e3b\u4e49\u53d1\u5c55\u53f2\u4e13\u9898\u7b2c\u4e8c\u671f","total_score":60},{"category":"20200806","total_score":60},{"category":"20200812","total_score":60},{"category":"20200901","total_score":90},{"category":"ypdj-20201112","total_score":70}],"message":"success"}
		const list = [];
		(result.data || []).map(item => {
			list.push(item.category);
		});
		setList(list);
	};
	useEffect(() => {
		getList();
	}, [123]);

	const [ clist, setClist ] = useState([]);
	const cInitial = (len = 3) => {
		const clistMap = {};
		for(let i = 1; i <= len; i++) {
			clistMap[moment().subtract(len - i, 'months')] = { days: [] };
		}
		clistMap[moment()] = { days: [] };
		// for(let i = 0; i <= len; i++) {
		// 	clistMap[moment().add(i, 'months')] = { days: [] };
		// }

		for(let m in clistMap) {
			const _m = moment(m);
			const monthDays = moment(_m.format('YYYY-MM'), 'YYYY-MM').daysInMonth();
			for(let i = 1; i <= monthDays; i++) {
				clistMap[m].days.push(moment(`${_m.format('YYYY')}/${_m.format('MM')}/${i}`))
			}
		}

		const clist = [];
		Object.keys(clistMap).map(m => {
			const _m = moment(m);
			const item = clistMap[m];
			const year = _m.format('YYYY');
			const month = _m.format('M');
			const monthFirstWeek = moment(`${year}/${month}/1`).startOf('month').weekday();
			const spaceDays = ((len) => {
				const arr = [];
				for(let i = 0; i < len; i++) {
					arr.push(null);
				}
				return arr;
			})(monthFirstWeek);
			clist.push({
				year,
				month,
				days: [...spaceDays, ...item.days]
			})
		});
		setClist(clist);
	};
	useEffect(() => {
		cInitial(3);
	}, [123]);

	return (
		<Page title="我的打卡日历">
			<section className="history-wrapper" style={{ height: window.innerHeight }}>
				<div className="header" style={{ backgroundImage: `url(${IMG_BANNER})` }} />
				<div className="calendar">
					{
						clist && clist.length > 0 && clist.map((c, idx) => {
							return (
								<div className="calendar-item" key={idx}>
									<div className="calendar-header">{c.year}年{c.month}月</div>
									<div className="weeks">
										<span>日</span><span>一</span><span>二</span><span>三</span><span>四</span><span>五</span><span>六</span>
									</div>
									{
										c.days && c.days.length > 0 && <div className="days">
											{
												c.days.map((d, didx) => {
													if(!d){
														return <div key={didx} className="day-item" />
													}
													let topic = moment(d).format('YYYYMMDD');
													const obj = Utils.getSearchByKey('obj');
													if(obj) {
														topic = obj + '-' + topic;
													}
													const isCheck = d && list && list.length > 0 && list.includes(topic);
													return <div key={didx} className="day-item" style={ isCheck ? { color: '#FE872A', fontWeight: 900 } : {}}>{d && moment(d).format('D')}</div>
												})
											}
										</div>
									}
								</div>
							)
						})
					}
				</div>
			</section>
		</Page>
	);
};

export default PageWrapper;
