/**
 * Created by jimmy on 2019/11/8.
 */
/**
 * Created by jimmy on 2019/8/13.
 */
import 'whatwg-fetch';
import * as Utils from './utils';
import * as CONST from './const';

export const getHeaders = (conf = {}) => {
	const token = window.sessionStorage.getItem('token');
	return {
		"Content-Type": "application/json; charset=utf-8",
		"Authorization": token || ''
	}
};

export const post = async(settings) => {
	let url = settings.url.startsWith('https://') || settings.url.startsWith('http://') ? settings.url : CONST.apiPrefix + settings.url;
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'POST',
		headers: getHeaders(settings.headers),
		body: JSON.stringify(settings.data || {})
	});
	Utils.loading.hide();

	response = await response.json();
	if ((response.status_code && response.status_code == 500) || response.status == 'failed') {
		Utils._alert(response.message || '系统异常，请稍后重试');
		return false;
	}

	return response;
};

export const get = async(settings = {}) => {
	let url = settings.url.startsWith('https://') || settings.url.startsWith('http://') ? settings.url : CONST.apiPrefix + settings.url;
	// let url = settings.url;

	if (settings.data) {
		let queryArr = [];
		for (let i in settings.data) {
			if (i && settings.data[i]) {
				queryArr.push(`${i}=${settings.data[i]}`);
			}
		}
		url += '?' + queryArr.join('&');
	}

	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'GET',
		headers: getHeaders(settings.headers)
	});
	Utils.loading.hide();

	response = await response.json();
	if (!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};
