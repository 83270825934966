/**
 * Created by jimmy on 2019/11/8.
 */
import * as CONST from './const';
import * as Request from './request';
import * as Utils from './utils';

const _wxRedirectUriReset = (uri = window.location.href) => {
	let hash, pureUri, pureUrl;
	hash = uri.split('#')[1];
	pureUri = uri.split('#')[0];
	pureUrl = pureUri.split('?')[0];

	let querys = (function (querys) {
		if (!querys) return {};
		let query = {};
		let _querysArr = querys.split('&');
		for (let i = 0, len = _querysArr.length; i < len; i++) {
			let qItem = _querysArr[i].split('=');
			query[qItem[0]] = qItem[1];
		}
		return query;
	})(pureUri.split('?')[1]);

	let queryArr = [];
	for (let i in querys) {
		if (querys[i] && i != 'code' && i != 'state') {
			queryArr.push(i + '=' + querys[i]);
		}
	}
	if (queryArr.length > 0) {
		pureUrl += '?' + queryArr.join('&')
	}
	if (!!hash) pureUrl += '#' + hash;

	return pureUrl;
};

// wechat user auth
const _wxLoginAuthorize = (option = {}) => {
	const url = `https://open.weixin.qq.com/connect/oauth2/authorize?${[
		`appid=${CONST.appId}`,
		`redirect_uri=${encodeURIComponent(_wxRedirectUriReset(window.location.href))}`,
		`response_type=code`,
		`scope=${option.scope || 'snsapi_base'}`,
		`state=${option.state || 'STATE_BASE'}`
	].join('&')}#wechat_redirect`;
	window.location.href = url;
	return;
};

// do login
const _login = async(opt = {}, callback) => {
	// for DEBUG
	// const openid = window.localStorage.getItem('openid') || 'ock0BuFEr-swvQGnEWQz2IHjbj511111Y';
	// return { openid };

	const code = Utils.getSearchByKey('code');
	const state = Utils.getSearchByKey('state');
	if (!code) return _wxLoginAuthorize();

	const result = await Request.post({
		url: '/user/login',
		data: {code}
	});
	const userInfo = result.data || null;
	if (userInfo && userInfo.wechatInfo && !userInfo.wechatInfo.nickname) {
		_wxLoginAuthorize({scope: 'snsapi_userinfo'});
		return;
	}

	return userInfo;
};
export const login = _login;

// 微信签名
export const sign = async() => {
	const result = await Request.get({
		url: '/user/wechat-config',
		data: {
			url: encodeURIComponent(window.location.href.split('#')[0])
		}
	});
	if (!result) return;

	window.wx.config({
		// debug: true,
		appId: result.data.appId || '',            // 必填，公众号的唯一标识
		timestamp: result.data.timestamp || '',    // 必填，生成签名的时间戳
		nonceStr: result.data.nonceStr || '',      // 必填，生成签名的随机串
		signature: result.data.signature || '',    // 必填，签名，见附录1
		jsApiList: [                                          // 必填，签名，见附录1
			"updateAppMessageShareData",
			"updateTimelineShareData",
			"onMenuShareAppMessage",
			"onMenuShareTimeline",
		]
	});
};

// wechat share functions
export const share = (conf = {}, showTip, callback) => {
	window.wx.ready(() => {
		const _conf = {
			title: '四史知识竞赛', // 分享标题
			desc: '巨量题库，等你来，火热比赛进行时', // 分享描述
			link: `${window.location.protocol || 'http:'}//${CONST.host}`, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
			imgUrl: `${window.location.protocol || 'http:'}//${CONST.host}/share.jpeg`, // 分享图标
			...conf
		};
		// alert(JSON.stringify(_conf));
		if (window.wx.updateAppMessageShareData) window.wx.updateAppMessageShareData(_conf);
		if (window.wx.onMenuShareAppMessage) window.wx.onMenuShareAppMessage(_conf);

		delete _conf.desc;
		if (window.wx.updateTimelineShareData) window.wx.updateTimelineShareData(_conf);
		if (window.wx.onMenuShareTimeline) window.wx.onMenuShareTimeline(_conf);
	});

	if (showTip) {
		Utils.toast.show('请点击右上角分享');
	}

	if (callback && typeof callback == 'function') {
		callback();
	}
};
