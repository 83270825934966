/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import Page from '../components/page';

import * as CONST from '../common/const';
import * as Utils from '../common/utils';
import * as Request from '../common/request';

import './against-result.less';

const PageWrapper = props => {
	const [data, setData] = useState({});
	const getAgainstData = async() => {
		const {fid} = props.match.params;
		const result = await Request.get({
			url: '/against/info',
			data: {
				id: fid
			}
		});
		if (!result) return;

		const initiator_score = Utils.getSearchByKey('initiator_score');
		const initiator_number = Utils.getSearchByKey('initiator_number');
		const challenger_score = Utils.getSearchByKey('challenger_score');
		const challenger_number = Utils.getSearchByKey('challenger_number');

		setData({
			...(result.data || {}),
			...{
				initiator_score,
				initiator_number,
				challenger_score,
				challenger_number
			}
		});
	};
	useEffect(() => {
		getAgainstData();
	}, [123]);

	const userInfo = Utils.user.getInfo();
	return (
		<Page title="结果">
			<section className="against-result-wrapper">
				<div className="againstor">
					<div className="user">
						<div className="detail">
							<div className="avatar" style={{backgroundImage: `url(${data && data.initiator_avatar || CONST.AVATAR_DEFAULT})`}}/>
							<div className="point">
								<em>{data.initiator_score}</em>分
							</div>
							<div className="result">答对{data.initiator_number}题</div>
						</div>
						<div className="name" style={{marginLeft: 10}}>{data && data.initiator_name}</div>
					</div>
					<div className="pk"/>
					<div className="user">
						<div className="name" style={{marginRight: 10}}>{data && data.challenger_name}</div>
						<div className="detail">
							<div className="avatar" style={{backgroundImage: `url(${data && data.challenger_avatar || CONST.AVATAR_DEFAULT})`}}/>
							<div className="point">
								<em>{data.challenger_score}</em>分
							</div>
							<div className="result">答对{data.challenger_number}题</div>
						</div>
					</div>
				</div>
				<div className={ userInfo.id == data.winner ? 'against-result-cover success' : 'against-result-cover fail' }/>
				{/*<div className="result-point">*/}
				{/*<div className="img"/>*/}
				{/*<span>+3</span>*/}
				{/*</div>*/}
				<div className="result-start" onClick={() => {
					window.location.href = '/against/mapping';
				}}>继续挑战
				</div>
				<Link className="check-order" to="/against/order">查看排行版</Link>
			</section>
		</Page>
	);
};

export default PageWrapper;
