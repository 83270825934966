/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';

import Page from '../components/page';

import * as CONST from '../common/const';
import * as Utils from '../common/utils';
import * as Request from '../common/request';

import './order-list.less';

const PageWrapper = props => {
	const [type, setType] = useState('week');
	const [list, setList] = useState([]);
	const getList = async() => {
		const result = await Request.get({
			url: '/against/rank',
			data: {
				type
			}
		});
		if (!result) return;
		setList(result.data);
	};
	useEffect(() => {
		getList();
	}, [type]);
	return (
		<Page title="排行榜" style={{height: window.innerHeight, overflow: 'auto'}}>
			<section className="order-list-wrapper">
				<div className="list-wrapper">
					<div className="tabs">
            <span className={ type == 'week' ? 'active' : '' } onClick={() => {
	            setType('week');
            }}>周排行</span>
						<span className={ type == 'month' ? 'active' : '' } onClick={() => {
							setType('month');
						}}>月排行</span>
					</div>
					<div className="list">
						{
							list && list.map((item, idx) => {
								let cls = 'item';
								if (idx == 0) cls += ' first';
								if (idx == 1) cls += ' second';
								if (idx == 2) cls += ' third';
								return (
									<div className={cls} key={idx}>
										<div className="info">
											<span className="num">{ idx > 2 ? idx + 1 : '' }</span>
											<div className="avatar" style={{backgroundImage: `url(${item.avatar || CONST.AVATAR_DEFAULT})`}}/>
											<span className="name">{item.username}</span>
										</div>
										<div className="point">
											获得积分<span>{item.total_score}</span>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</section>
		</Page>
	);
};

export default PageWrapper;
