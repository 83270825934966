/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import moment from "moment";

import Page from '../components/page';

import * as Utils from '../common/utils';
import * as CONST from '../common/const';
import * as Request from '../common/request';

import './dakaobj-home.less';

import IMG_HOME_BANNER from '../common/images/home-banner.png';
import IMG_STUDY_BANNER from '../common/images/study-banner.png';
import IMG_CWRAP from '../common/images/cwrap.png';

// const IMG_HOME_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/home-banner.png';
// const IMG_STUDY_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/study-banner.png';
const IMG_AGAINST_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/against-banner.png';
const IMG_CERT_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/cert-banner.jpg';
const IMG_ORDER_BANNER = 'https://qp-competition.oss-cn-shanghai.aliyuncs.com/order-banner.jpg';

let timer = null;
const PageWrapper = props => {
	const [registerFlag, setRegisterFlag] = useState(false);
	const [userInfo, setUserInfo] = useState(null);
	const [ obj, setObj ] = useState('');
	const initial = () => {
		clearTimeout(timer);
		timer = null;
		const userInfo = Utils.user.getInfo();
		if (!userInfo) {
			timer = setTimeout(() => {
				initial();
			}, 333);
		}
		if (userInfo && userInfo.id && userInfo.id_card) {
			setUserInfo(userInfo);
			setRegisterFlag(false);
		} else {
			setRegisterFlag(true);
		}

		const { obj } = props.match.params;
		if(obj) {
			setObj(obj);
		}
	};
	useEffect(() => {
		initial()
	}, [123]);

	const [list, setList] = useState([]);
	const [total, setTotal] = useState(0);
	const getDakaList = async() => {
		const { obj } = props.match.params;
		const data = {};
		if(obj) {
			data.all = obj;
		}
		let result = await Request.get({
			url: '/topic/category-lists',
			data
		});
		if(!result) return;
		// const result = {"code":1,"data":[{"category":"\u515a\u53f2\u4e13\u9898\u7b2c\u4e00\u671f","total_score":70},{"category":"\u515a\u53f2\u4e13\u9898\u7b2c\u4e00\u671f","total_score":80},{"category":"\u515a\u53f2\u4e13\u9898\u7b2c\u4e00\u671f","total_score":90},{"category":"\u515a\u53f2\u4e13\u9898\u7b2c\u4e8c\u671f","total_score":90},{"category":"\u6539\u9769\u5f00\u653e\u53f2\u4e13\u9898\u7b2c\u4e00\u671f","total_score":80},{"category":"\u793e\u4f1a\u4e3b\u4e49\u53d1\u5c55\u53f2\u4e13\u9898\u7b2c\u4e8c\u671f","total_score":60},{"category":"20200806","total_score":60},{"category":"20200812","total_score":60},{"category":"20200901","total_score":90},{"category":"ypdj-20201112","total_score":70}],"message":"success"}
		const list = [];
		let total = 0;
		(result.data || []).map(item => {
			if(item.category.indexOf(obj+'-202') > -1) {
				list.push(item.category);
				total += item.total_score;
			}
		});
		setList(list);
		setTotal(total);
	};
	useEffect(() => {
		getDakaList();
	}, [userInfo]);

	const [certCnt, setCertCnt] = useState(0);
	const getCertList = async() => {
		let list = window.sessionStorage.getItem('certs');
		list = list && typeof list == 'string' ? JSON.parse(list) : null;
		if(!list) {
			let result = await Request.get({
				url: '/topic/category-lists'
			});
			list = !result ? [] : result.data;
			if(!result) {
				list = [];
			}else{
				list = result.data;
				if(list && list.length) {
					window.sessionStorage.setItem('certs', JSON.stringify(list));
				}
			}
		}

		if(!list) list = [];
		const titleList = list.map(item => item.category);
		let cnt = 0;
		CONST.projectConf.topics.map(item => {
			if (titleList.includes(item.title)) {
				cnt += 1;
			}
		});
		setCertCnt(cnt);
	};
	useEffect(() => {
		getCertList();
	}, [userInfo]);

	const [registerForm, setRegisterForm] = useState({});
	const submit = async() => {
		// 信息校验
		if (!registerForm.name) return Utils._alert('请输入姓名');
		if (!CONST.regRules.mobile.test(registerForm.phone)) return Utils._alert('请输入合法手机号码');
		if (!registerForm.danwei || registerForm.danwei == 'none') return Utils._alert('请输入所在单位');
		if (!CONST.regRules.idCard.test(registerForm.id_card)) return Utils._alert('请输入身份证号码');

		// console.log('registerForm', registerForm);
		// return;

		let userInfo = Utils.user.getInfo();
		if (userInfo && userInfo.id) {
			const result = await Request.post({
				url: '/user/update',
				data: {
					...registerForm
				}
			});
			if (!result) return;

			userInfo = { ...userInfo, ...registerForm };
		}else{
			const result = await Request.post({
				url: '/user/login',
				data: {
					...registerForm,
					openid: userInfo.openid,
					avatar: userInfo.avatar,
					nickname: userInfo.nickname
				}
			});
			if (!result) return;

			const token = `${result.data.token_type} ${result.data.access_token}`;
			window.sessionStorage.setItem('token', token);
			userInfo = result.data.user;
		}

		Utils.user.setInfo(userInfo);
		setUserInfo(userInfo);
		setRegisterFlag(false);
		setRegisterForm({});
	};

	return (
		<Page title='"四史"学习教育'>
			<section className="ypdj-home-wrapper">
				<div className="userinfo">
					<div className="avatar" onClick={() => {
						setRegisterForm({
							id_card: userInfo && userInfo.id_card || 'none',
							danwei: userInfo && userInfo.danwei || 'none',
							name: userInfo && userInfo.name || '',
							phone: userInfo && userInfo.phone || ''
						});
						setRegisterFlag(true);
					}}>
						<div className="img" style={{backgroundImage: `url(${userInfo && userInfo.avatar || CONST.AVATAR_DEFAULT})`}}/>
						<span>{ userInfo && userInfo.name || '未登录' } ></span>
					</div>
				</div>

				<div className="cwrap">
					<div className="wrapinner">
						<span>{moment().format('YYYY年M月')}</span>
						<span><em>{moment().format('D')}</em>号</span>

						<div className="dakainfo"><span>累计打卡数：</span><em>{ list && list.length || 0 }</em></div>
						<div className="dakainfo"><span>累计打卡积分：</span><em>{ total || 0 }</em></div>
					</div>
				</div>

				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
					{
						list && list.length > 0 && list.includes(obj ? obj + '-' + moment().format('YYYYMMDD') : moment().format('YYYYMMDD')) &&
						<div className="daka-action" style={{ backgroundColor: '#bfbfbf' }}>今日已打卡</div> ||
						<Link className="daka-action" to={obj ? `/daka?obj=${obj}` : '/data'}>打卡</Link>
					}
					<Link className="mydaka-action" to={obj ? `/history?obj=${obj}` : '/history'}>我的打卡</Link>
				</div>
			</section>

			{ registerFlag && <div className="mask"></div> }
			{
				registerFlag && <div className="register-wrapper">
					<div className="title">用户登录</div>
					<div className="form-wrap">

						<div className="form-item">
							<input
								placeholder="姓名"
								onChange={e => {
									const value = e.target.value;
									setRegisterForm({...registerForm, name: value});
								}}
								defaultValue={userInfo && userInfo.name || undefined}
							/>
						</div>

						<div className="form-item">
							<input
								placeholder="手机号"
								maxLength="11"
								onChange={e => {
									const value = e.target.value;
									setRegisterForm({...registerForm, phone: value});
								}}
								defaultValue={userInfo && userInfo.phone || undefined}
							/>
						</div>

						<div className="form-item">
							<input
								placeholder="所在单位"
								onChange={e => {
									const value = e.target.value;
									setRegisterForm({...registerForm, danwei: value});
								}}
								defaultValue={userInfo && userInfo.danwei || undefined}
							/>
						</div>

						<div className="form-item">
							<input
								placeholder="身份证号码"
								onChange={e => {
									const value = e.target.value;
									setRegisterForm({...registerForm, id_card: value});
								}}
								defaultValue={userInfo && userInfo.id_card || undefined}
							/>
						</div>

					</div>
					<div className="formbtn-wrap">
						<span className="submit" onClick={submit}>确定</span>
						<span className="cancel" onClick={() => {
							setRegisterFlag(false);
							setRegisterForm({});
						}}>取消</span>
					</div>
				</div>
			}

			{
				(!userInfo || !userInfo.id || !userInfo.id_card) && <div className="ypdj-home-wrapper-cover" onClick={(e) => {
					e.stopPropagation();
					e.preventDefault();

					setRegisterFlag(true);
					setRegisterForm({});
				}}/>
			}
		</Page>
	);
};

export default PageWrapper;
