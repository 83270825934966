/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";

import Page from '../components/page';

import * as CONST from '../common/const';
import * as Request from '../common/request';
import * as Utils from '../common/utils';

import './answer-result.less';

const PageWrapper = props => {
	const [data, setData] = useState({});
	const getData = async() => {
		const {id} = props.match.params;
		const result = await Request.get({
			url: '/topic/info-by-id',
			data: {
				id
			}
		});
		if (!result) return;
		setData(result.data.info || {});
	};
	useEffect(() => {
		getData();
	}, [123]);

	const [topic, setTopic] = useState({});
	const getTopic = () => {
		const {topicId} = props.match.params;
		const topic = CONST.projectConf.getTopicById(topicId);
		setTopic(topic);
	};
	useEffect(() => {
		getTopic();
	}, [123]);

	const userInfo = Utils.user.getInfo();
	return (
		<Page title="首页">
			<section className="topic-answer-result-wrapper">
				<div className="btn-wrapper">
					<div className="result">本次专题学习获得<span>{data && data.total_score}</span>分</div>
					<Link className="item" to="/certs">查看证书</Link>
					<div className="item blank" onClick={() => {
						window.location.href = '/topics';
					}}>再来一次</div>
				</div>
			</section>
			{/*<section className="topic-answer-result-wrapper">
				<div className="qr-wrapper">
					<div className="qrheader">
						<em />
						<span>{topic.title}</span>
					</div>
					<div className="userinfo">
						<div className="avatar">
							<div className="img" style={{backgroundImage: `url(${userInfo && userInfo.avatar || IMG_AVATAR})`}}/>
							<span>{userInfo && userInfo.name}</span>
						</div>
						<div className="point">
							<span>{data.total_score}</span>分
						</div>
					</div>
					<div className="info">
						<div className="info-item"><label>用时</label><span style={{color: '#007aff'}}>{data.spend_time && Utils.secondFormat(data.spend_time, data.spend_time > 60 ? 'mm分ss秒' : 'ss秒')}</span></div>
						<div className="info-item"><label>错题数</label><span style={{color: '#ff6e65'}}>{data && data.number ? 10 - data.number : 0}</span></div>
						<div className="info-item"><label>正确率</label><span style={{color: '#10a926'}}>{data && data.number ? data.number / 10 * 100 : 0}%</span></div>
					</div>
				</div>
				<Link className="btn-brand" to="/certs">查看证书</Link>
				<div className="btn-again" onClick={() => {
					window.location.href = '/topics';
				}}>再来一次
				</div>
			</section>*/}
		</Page>
	);
};

export default PageWrapper;
