/**
 * Created by jimmy on 2019/9/29.
 */

import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';

import Page from '../components/page';

import * as Utils from '../common/utils';
import * as Request from '../common/request';
import * as CONST from '../common/const';

import './against-map.less';

// image

const socket = null; // window.io(CONST.wsApi);

let refreshCallerTimer = null;

const PageWrapper = props => {
	const [initAgainst, setInitAgainst] = useState(null);
	const [initChoose, setInitChoose] = useState(null);
	const doInitAgainst = async() => {
		const result = await Request.post({
			url: '/against/initiate'
		});
		if (!result) return;
		setInitAgainst(result.data);
	};
	const getAgainstInfo = async (id) => {
		const result = await Request.get({
			url: '/against/info',
			data: {
				id
			}
		});
		if (!result) return;
		setInitChoose(result.data);
	};
	useEffect(() => {
		const id = Utils.getSearchByKey('fid');
		if(!!id) {
			getAgainstInfo(id);
			updateChoose(id);
		}else{
			doInitAgainst();
		}
	}, [123]);

	const updateChoose = async (id) => {
		const fight_status = Utils.getSearchByKey('fight_status');
		if(!fight_status) return;

		await Request.post({
			url: '/against/choose',
			data: {
				id,
				fight_status
			}
		});
	};
	const doInitChoose = async() => {
		if (!initAgainst) return;

		const data = { id: initAgainst.id };
		const fight_status = Utils.getSearchByKey('fightStatus');
		if(fight_status) {
			data.fight_status = fight_status;
		}
		const result = await Request.post({
			url: '/against/choose',
			data
		});
		if (!result) return;

		if (result.data && result.data.id) {
			setInitChoose(result.data);
		}else{
			const userInfo = Utils.user.getInfo();
			setInitChoose({
				initiator_avatar: userInfo.avatar,
				initiator_name: userInfo.name,
			});
		}

		refreshCaller(10, result.data);
	};
	useEffect(() => {
		doInitChoose();
	}, [initAgainst]);

	const quitAgainst = async() => {
		const result = await Request.post({
			url: '/against/quit',
			data: {
				id: initChoose.id
			}
		});
		if (!result) return;

		if(result.code == 1) {
			const userInfo = Utils.user.getInfo();
			if(!socket) return;
			socket.emit('event-against', { key: initChoose.id, order: 'leave', isInitiator: userInfo.id == initChoose.initiator });
		}
	};

	const [ fightStatus, setFightStatus ] = useState(false);
	const [ fighterLeave, setFighterLeave ] = useState(false);
	Utils.selfEvent.addEvent('fightStatusInitial', async (data) => {
		if(data && data.fight_status == 2) {
			setFightStatus(true);
		}else if(data && data.fight_status == 3) {
			await Request.post({
				url: '/against/quit',
				data: {
					id: data.id
				}
			});
			setFighterLeave(true);
			setTimeout(() => {
				setFighterLeave(false);
				window.location.reload();
			}, 2500);
		}

		refreshCaller(0);
	});

	useEffect(() => {
		if(initChoose && initChoose.id) {
			const userInfo = Utils.user.getInfo();
			if(!socket) return;
			socket.on('event-against-' + initChoose.id, function (res) {
				if(res && res.order == 'leave') {
					const isInitiator = userInfo.id == initChoose.initiator;
					if(isInitiator == res.isInitiator) {
						props.history.push("/home");
					}else{
						let maskDom = document.getElementById('mappingMask');
						if(maskDom) maskDom.style.display = 'block';
						Utils.toast.show('您的对手已经离开战场，本次竞赛将在5秒后自动结束，请稍后重新匹配对手');
						setTimeout(() => {
							maskDom.style.display = 'none';
							Utils.toast.hide();
							props.history.push("/home");
						}, 5000);
					}
				}
			});
		}
	}, [initChoose]);

	const refreshCaller = async (sec, fighter) => {
		clearTimeout(refreshCallerTimer);
		refreshCallerTimer = null;
		if(sec <= 0) {
			return;
		}
		if(sec == 3) {
			Utils._alert(fighter && fighter.id ? '对手暂无应答，您可以尝试重新发起对战' : '暂无匹配到合适对手，请重新发起对战');
		}

		refreshCallerTimer = setTimeout(() => {
			refreshCaller(sec - 1, fighter);
		}, 1000)
	};

	const starFlag = initChoose && initChoose.id && fightStatus;
	let loadingTxt = '正在匹配对手……';
	if(initChoose && initChoose.id) {
		loadingTxt = fightStatus ? '准备开始中……' : '等待对手应战……';
	}
	return (
		<Page title="对手匹配">
			<section className="against-map-wrapper">
				<div className="loading-txt">{loadingTxt}</div>
				<div className="againstor">
					<div className="user">
						<div className="img" style={{backgroundImage: `url(${initChoose && initChoose.initiator_avatar || CONST.AVATAR_DEFAULT})`}}/>
						<span>{initChoose && initChoose.initiator_name}</span>
						<div className="status start"/>
					</div>
					<div className="vs"/>
					<div className="user">
						<div className="img" style={{backgroundImage: `url(${initChoose && initChoose.challenger_avatar || CONST.AVATAR_DEFAULT})`}}/>
						<span>{initChoose && initChoose.challenger_name || ''}</span>
						<div className={ starFlag ? 'status start' : 'status wait' }/>
					</div>
				</div>
				{
					starFlag &&
						<Link className="against-btn start" to={`/against/fight/${initChoose && initChoose.id || ''}`}>开始对战</Link> ||
						<div className="against-btn wait">开始对战</div>
				}

				<div className="against-btn leave" onClick={async () => {
					if(initChoose && initChoose.id) {
						refreshCaller(0);
						await quitAgainst();
					}else{
						window.location.href = '/';
					}
				}}>退出比赛
				</div>
			</section>

			<div className="mask" id="mappingMask" style={{ display: 'none' }} />

			{
				!!fighterLeave && <div className="leave-tip-wrap">
					<div className="leave-tip">
						<img src="https://qp-competition.oss-cn-shanghai.aliyuncs.com/cry.png" />
						<span>对手拒绝你的挑战</span>
						<span>系统在<i className="sec">2</i>秒后将重新匹配对手</span>
					</div>
				</div>
			}
		</Page>
	);
};

export default PageWrapper;
